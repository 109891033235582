import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  title: {},
  buttons:{
    marginTop: '15px !important',
    [theme.breakpoints.down('md')]: {
      marginTop: '30px !important',
    }
  },
  button: {
    borderRadius: '36px !important'
  },
  buttonLeft: {
    borderRadius: '36px !important'
  },
  modalParent:{
    overflow: 'auto',
  },
  modal: {
  position: 'absolute',
  top: '50%',
  minHeight: '400px',
  /*[theme.breakpoints.only('xs')]: {
    top: '65%',
    marginTop: '15px',
  },*/
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '800px',
  padding: '15px',
  backgroundColor: 'white',
  borderRadius: '12px',
  boxShadow: '0px 1px 3px 0px rgb(50 50 50 / 20%), 0px 1px 1px 0px rgb(50 50 50 / 14%), 0px 2px 1px -1px rgb(50 50 50 / 12%)',
  p: 4,
  '& $button': {
    padding: 0,
    width: '25%',
    //marginLeft: '50%',
    float: 'right',
    height: 43,
    lineHeight: '44px',
    //position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      width: '45%',
    },
    //right: 0,
    //bottom: 0,
    boxShadow: 'none !important',//theme.shadows[10],
    borderRadius: '20px 0 0 0'
  },
  '& $buttonLeft': {
    padding: 0,
    width: '25%',
    //marginRight: '55%',
    float: 'left',
    height: 43,
    lineHeight: '44px',
    //position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      width: '45%',
    },
    //left: 0,
    //bottom: 0,
    boxShadow: 'none !important',//theme.shadows[10],
    borderRadius: '20px 0 0 0'
  }
},
  /* Default Card */
  defaultCard: {
    marginLeft: '15px',
    borderRadius: 12,
    position: 'relative',
    backgroundColor: '#fff !important',
    color: '#012d3a !important',
    /*width: '100%',*/
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      minHeight: '342px',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
    /*margin: theme.spacing(3, 2),*/
    '& figure': {
      borderRadius: '0 0 50px 0',
      overflow: 'hidden',
      height: 120,
      margin: 0,
      marginBottom: '10px',
      '& img': {
        height: '100%',
        minWidth: '100%'
      }
    },
    '& h6': {
      color: '#012d3a !important',
      /*marginBottom: '10px',*/
    },
    '& p': {
      /*height: 70,*/
      overflow: 'hidden',
      marginBottom: '10px',
      color: '#012d3a',
    }
  },
  text: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2.5)
    }
  },
  checkoutForm:{
    height: '100%',
    '& button':{
      minWidth: '192px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '144px'
      }
    }
  },
  /* Testimonial Card */
  testiCard: {
    direction: 'ltr',
    position: 'relative',
  },
  paper: {
    padding: theme.spacing(6, 2, 0, 0),
    width: 300,
    height: 300,
    borderRadius: '0 50% 50% 50%',
    [theme.breakpoints.down('xs')]: {
      width: 280,
      height: 280
    },
    '& p': {
      height: 100,
      padding: theme.spacing(3),
      overflow: 'hidden',
      marginBottom: theme.spacing(2)
    }
  },
  rating: {
    marginLeft: theme.spacing(3)
  },
  starIcon: {
    color: '#FFC107'
  },
  starIconDisable: {
    color: theme.palette.divider
  },
  avatar: {
    border: '1px solid #FFF',
    boxSizing: 'content-box',
    marginLeft: theme.spacing(1),
    borderRadius: '50% 50% 0 50% !important',
    boxShadow: theme.shadows[8]
  },
  person: {
    display: 'flex',
    marginTop: theme.spacing(-10),
    justifyContent: 'flex-end',
    marginRight: theme.spacing(5),
    position: 'relative',
    '& $avatar': {
      width: 70,
      height: 70,
    },
    '& $title': {
      fontWeight: theme.typography.fontWeightRegular
    },
  },
  name: {
    textAlign: 'right',
    marginLeft: theme.spacing(2),
    '& h6': {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  /* Profile Card */
  divider: {},
  photo: {},
  nameCard: {},
  profile: {
    overflow: 'visible',
    borderRadius: '12px !important',
    margin: '0.5rem 0 1rem 0',
    '& $divider': {
      borderBottom: 'none',
      borderColor: theme.palette.divider,
      background: 'none',
      margin: theme.spacing(0, 2)
    }
  },
  personBlock: {
    display: 'flex',
    '& $photo': {
      width: 80,
      height: 80,
      position: 'relative',
      borderRadius: '0 50% 50% 50%',
      top: -8,
      left: -8,
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
      },'& i': {
        fontSize: '35px'
      }
    },
    '& $nameCard': {
      padding: theme.spacing(1)
    },
    '& h4': {
      fontSize: 22,
      margin: 0,
      fontWeight: theme.typography.fontWeightRegular
    },
    '& p': {
      fontSize: 16,
      marginBottom: theme.spacing(2),
      color: theme.palette.text.secondary
    }
  },
  action: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& $button': {
      height: 36,
      [theme.breakpoints.up('sm')]: {
        width: 100
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: theme.spacing(2)
      }
    }
  },
  property: {
    '& span': {
      color: theme.palette.text.disabled,
      fontSize: 14,
      display: 'inline-block',
      marginRight: theme.spacing(4),
      '& i': {
        fontSize: 24
      }
    }
  },
  /* Address Card */
  paperAddress: {
    padding: theme.spacing(2, 0, 3)
  },
  icon: {
    color: '#021b22',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    top: 8,
    position: 'relative'
  }
}));

export default useStyles;
