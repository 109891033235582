import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/LocalPhone';
import LocationIcon from '@mui/icons-material/LocationOn';
import QueryBuilder from '@mui/icons-material/QueryBuilder';
import useStyles from './cards-style';

function Address(props) {
  const classes = useStyles();
  const {
    name, phone,
    email, address, config
  } = props;
  return (
    <div className={classes.paperAddress}>
      {name &&
      <Typography variant="h6" display="block">
        {name}
      </Typography>
      }
      <Grid container>
        {phone !== '' &&
        <Grid item md={6} xs={12}>
          <PhoneIcon className={classes.icon} style={{verticalAlign: 'initial'}} />
          <span>{phone}</span>
        </Grid>
        }
        {email !== '' &&
        <Grid item md={6} xs={12}>
          <EmailIcon className={classes.icon} style={{verticalAlign: 'initial'}} />
          {email}
        </Grid>
        }
        {address !== '' &&
        <Grid item xs={12}>
          <LocationIcon className={classes.icon} style={{verticalAlign: 'initial'}} />
          {address}
        </Grid>
        }
        {address !== '' &&
        <Grid item xs={12} container>
          <Grid item xs={4}>
            <QueryBuilder className={classes.icon} style={{verticalAlign: 'initial'}} /> Horario:
          </Grid>
          <Grid item xs={8} className="pt-4">
            <Grid container>
              <Grid item xs={4}>
                Lunes:
              </Grid>
              <Grid item xs={8}>
              {(config.lunesd === '' && config.lunesh === '' && config.lunesd2 === '' && config.lunesh2 === '') && 'Cerrado'}
                {(config.lunesd !== '' && config.lunesd+' - '+config.lunesh+(config.lunesd2 !== '' ? ',   ' : ''))}
                {(config.lunesd2 !== '' && config.lunesd2+' - '+config.lunesh2)}
              </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                  Martes:
                </Grid>
                <Grid item xs={8}>
                  {(config.martesd === '' && config.martesh === '' && config.martesd2 === '' && config.martesh2 === '') && 'Cerrado'}
                  {(config.martesd !== '' && config.martesd+' - '+config.martesh+(config.martesd2 !== '' ? ', ' : ''))}
                  {(config.martesd2 !== '' && config.martesd2+' - '+config.martesh2)}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                  Miercoles:
                </Grid>
                <Grid item xs={8}>
                  {(config.miercolesd === '' && config.miercolesh === '' && config.miercolesd2 === '' && config.miercolesh2 === '') && 'Cerrado'}
                  {(config.miercolesd !== '' && config.miercolesd+' - '+config.miercolesh+(config.miercolesd2 !== '' ? ', ' : ''))}
                  {(config.miercolesd2 !== '' && config.miercolesd2+' - '+config.miercolesh2)}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                  Jueves:
                </Grid>
                <Grid item xs={8}>
                  {(config.juevesd === '' && config.juevesh === '' && config.juevesd2 === '' && config.juevesh2 === '') && 'Cerrado'}
                  {(config.juevesd !== '' && config.juevesd+' - '+config.juevesh+(config.juevesd2 !== '' ? ', ' : ''))}
                  {(config.juevesd2 !== '' && config.juevesd2+' - '+config.juevesh2)}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                  Viernes:
                </Grid>
                <Grid item xs={8}>
                  {(config.vieresd === '' && config.viernesh === '' && config.viernesd2 === '' && config.viernesh2 === '') && 'Cerrado'}
                  {(config.viernesd !== '' && config.viernesd+' - '+config.viernesh+(config.viernesd2 !== '' ? ', ' : ''))}
                  {(config.viernesd2 !== '' && config.viernesd2+' - '+config.viernesh2)}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                  Sábado:
                </Grid>
                <Grid item xs={8}>
                  {(config.sabadod === '' && config.sabadoh === '' && config.sabadod2 === '' && config.sabadoh2 === '') && 'Cerrado'}
                  {(config.sabadod !== '' && config.sabadod+' - '+config.sabadoh+(config.sabadod2 !== '' ? ', ' : ''))}
                  {(config.sabadod2 !== '' && config.sabadod2+' - '+config.sabadoh2)}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={4}>
                  Domingo:
                </Grid>
                <Grid item xs={8}>
                  {(config.domingod === '' && config.domingoh === '' && config.domingod2 === '' && config.domingoh2 === '') && 'Cerrado'}
                  {(config.domingod !== '' && config.domingod+' - '+config.domingoh+(config.domingod2 !== '' ? ', ' : ''))}
                  {(config.domingod2 !== '' && config.domingod2+' - '+config.domingoh2)}
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
      </Grid>
    </div>
  );
}

export default Address;
