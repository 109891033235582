import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useStyles from './header-style';

function Settings(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const { invert, t } = props;
  return (
    <div className={classes.setting}>
      <IconButton
        aria-describedby={id}
        aria-label="Settings"
        onClick={handleClick}
        className={
          clsx(
            classes.icon,
            invert && classes.invert
          )
        }
      >
        <PersonIcon fontSize="inherit" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List
          component="nav"
          className={classes.titleMenu}
          aria-label="Mode-menu"
          subheader={(
            <ListSubheader component="div">
              Mi cuenta
            </ListSubheader>
          )}
        >
          <ListItem
          button
          component="a"
          href="#">
            <Typography component="div">
              Mis reservas
            </Typography>
          </ListItem>
          <ListItem
          button
          component="a"
          href="#">
            <Typography component="div">
              Datos personales
            </Typography>
          </ListItem>
        </List>
        <Divider />
      </Popover>
    </div>
  );
}

Settings.defaultProps = {
  invert: false
};

export default Settings;
