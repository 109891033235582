import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// Get all Auth methods
import { isUserAuthenticated } from '../auxiliares/funciones';

const AppRoute = ({ component: Component, isAuthProtected, props: props, ...rest}) => {
  if (isAuthProtected && !isUserAuthenticated()) {
    return (
      <Navigate to="/login" state={{ from: props.location }} exact />
    );
  }else{
    return(
      <Routes>
        <Route
          {...rest}
          element={ <Component {...props} /> }
        />
      </Routes>
    )
  }
};

export default AppRoute;
