import Principal from '../paginas/index';
import Pageslogin from '../paginas/login';
import Logout from '../paginas/logout';

import Reservas from '../reservas'
//import Catalogo from '../catalogo'

import E404 from "../paginas/404";
import E500 from "../paginas/500";

/*import CatalogoTienda from "../catalogo/paginas/catalogoTienda";
import Contacto from "../catalogo/paginas/contacto";
import Producto from "../catalogo/paginas/producto";
import PoliticaPrivacidad from "../catalogo/paginas/politicaPrivacidad";
import AvisoLegal from "../catalogo/paginas/avisoLegal";
import Cookies from "../catalogo/paginas/cookies";*/


const authProtectedRoutes = [

  /*{ exact: true, path: "/app", component: Partidos, profiles:[1] },


  { exact: true, path: "/jugadores", component: Jugadores , name:'Jugadores', profiles:[1], icon:'mdi mdi-account-supervisor-outline',menu:'Jugadores'},
  { exact: true, path: "/jugador", component: Jugador , name:'Crear Jugador', profiles:[1], icon:'mdi mdi-account-multiple-plus-outline',menu:'Jugadores'},
  { exact: true, path: "/jugador/:id", component: Jugador , name:'Detalle Jugador', profiles:[1], icon:'mdi mdi-account-multiple-plus-outline'},

  { exact: true, path: "/partidos", component: Partidos , name:'Partidos', profiles:[1], icon:'mdi mdi-clipboard-list-outline', menu:'Partidos'},
  { exact: true, path: "/historico", component: Historico , name:'Histórico', profiles:[1], icon:'mdi mdi-clipboard-list', menu:'Partidos'},
  { exact: true, path: "/partido", component: Partido , name:'Crear Partido', profiles:[1], icon:'mdi mdi-clipboard-list', menu:'Partidos'},
  { exact: true, path: "/partido/:id", component: Partido , name:'Detalle Partido', profiles:[1], icon:'mdi mdi-clipboard-list'},
  { exact: true, path: "/partido-cierre/:id", component: CierrePartido , name:'Cierre Partido', profiles:[1], icon:'mdi mdi-clipboard-list'},

  { exact: true, path: "/asistencias", component: Asistencias , name:'Asistencias', profiles:[1], icon:'mdi mdi-calendar-weekend-outline', menu:'Estadísticas'},
  { exact: true, path: "/faltas", component: Faltas , name:'Faltas', profiles:[1], icon:'mdi mdi-account-badge-horizontal-outline', menu:'Estadísticas'},
  { exact: true, path: "/deudas", component: Deudas , name:'Deudas', profiles:[1], icon:'mdi mdi-cash', menu:'Estadísticas'},*/

  //OTROS 2
  { exact: true, path: "/500", component: E500 ,name:'Error 500', profiles:[1]}
];

const publicRoutes = [
  { path: "/reservas/*", component: Reservas },
  /*{ exact: true, path: "/catalogo", component: Catalogo },
  { path: "/catalogo/producto/:id", component: Producto },
  { path: "/catalogo/categoria/:id", component: CatalogoTienda, pagina: "categorias" },
  { exact: true, path: "/catalogo/contacto", component: Contacto },
  { exact: true, path: "/catalogo/politica-privacidad", component: PoliticaPrivacidad },
  { exact: true, path: "/catalogo/aviso-legal", component: AvisoLegal },
  { exact: true, path: "/catalogo/cookies", component: Cookies },
  { exact: true, path: "/catalogo/error", component: Error },*/
  /*{ path: "/logout", component: Logout },
  { path: "/login", component: Pageslogin },*/
  { path: "/404", component: E404 },
  { exact: true, path: "/",  component: Principal}
];

export { authProtectedRoutes, publicRoutes };
