import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import ScrollAnimation from 'react-scroll-animation-wrapper';
import clsx from 'clsx';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ProfileCard from '../Cards/Profile';
import useTitle from '../Title/title-style';
import useStyles from './ask-doctors-style';
import { getIndividualItemArray, getImagenesServicio } from "../../../auxiliares/funciones";

function AskDoctors(props) {
  // Theme breakpoints
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const classes = useStyles();
  const title = useTitle();
  const [selectedIndex, setSelectedIndex] = useState('Todos');
  //const [categories, setCategories] = useState([]);
  const [services, setServices] = useState(props.servicios);

  let cats = getIndividualItemArray(props.servicios,'categoria');
  cats.unshift("Todos");
  const categories = cats;

  let urlID = '';
  const queryString = window.location.search;
  if(queryString !== null){
    const urlParams = new URLSearchParams(queryString);
    urlID = urlParams.get('serv');
  }

  function handleListItemClick(event, index) {
    setSelectedIndex(index);
    if(index === 'Todos'){
      setServices(props.servicios)
    }else{
      setServices(props.servicios.filter(s => s.categoria === index))
    }
  }

  const renderCard = (item, index) => (
    <ProfileCard
      key={index.toString()}
      name={item.nombre}
      description={item.descripcion}
      id={item.id}
      title={item.categoria}
      image={getImagenesServicio(props.config,item)}
      icon={item.icono}
      config={props.config}
      rating={item.precio}
      exp={item.duracion}
      reservable={item.reservable}
      mailContact={props.config.mail}
      setLoading={props.setLoading}
      abreModalReserva={props.abreModalReserva}
      open={item.id === urlID}
      precioPorPersona={item.precioPorPersona}
      min={item.cantMinima} max={item.cantMaxima}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.deco} />
      <Container fixed={isDesktop} id="ContainerServicios">
        <Grid container spacing={isDesktop ? 3 : 0} justifyContent="center">
          <Grid item md={12} sm={12} xs={12} style={{margin: '0px 10px'}}>
            <Typography variant="h4" className={title.primary} >
              Servicios disponibles
            </Typography>
          </Grid>
          <Grid item md={2} sm={9} xs={12}>
            <ScrollAnimation
              animateOnce
              animateIn="fadeInLeftShort"
              offset={-100}
              delay={200}
              duration={0.3}
            >
              <div className={classes.sideFilter}>
                {/*<Typography variant="h4" className={title.primary}>
                  Servicios disponibles
                </Typography>*/}
                <List component="nav">
                  {categories.map((item, index) => (
                    <ListItem
                      button
                      key={index.toString()}
                      className={clsx(classes.filter)} style={selectedIndex === item ? { color: '#fff', backgroundColor: '#043e50'} : {}}
                      onClick={event => handleListItemClick(event, item)}
                    >
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </ScrollAnimation>
          </Grid>
          <Grid item lg={8} md={10} xs={12}>
            <div className={classes.massonry}>
              <Grid container spacing={isDesktop ? 6 : 4}  style={isDesktop ? {marginTop: '-25px'} : {}}>
                {services.map((item, index) =>(
                  <Grid item sm={6} xs={12} key={index.toString()}>
                    <ScrollAnimation
                      animateOnce
                      animateIn="fadeInUpShort"
                      offset={-100}
                      delay={index * 50}
                      duration={0.1}
                    >
                      <div>
                        {renderCard(item, index)}
                      </div>
                    </ScrollAnimation>
                  </Grid>
                ))}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
export default AskDoctors;
