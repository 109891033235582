import React, { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Container, Typography} from '@mui/material';
import { useTheme } from '@mui/styles';
import useStyles from './theme/otherPages';


function Landing(props) {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  return (
    <React.Fragment>
      <div className={classes.mainWrap}>
        <Container className={classes.containerWrap}>
          <Typography component="h1" className={classes.title}>Política de privacidad</Typography>
          { props.config.politicaPrivacidad.length > 50 ?
          <Container className={classes.body} dangerouslySetInnerHTML={{ __html: props.config.politicaPrivacidad }}></Container>
          : <Container maxWidth="md" className={classes.body}>

          <Typography component="h4">1. Identificación y datos de contacto del Responsable</Typography>

            <strong>{props.config.nombreEmpresa}</strong>, domiciliada en {props.config.direccionCompleta}, con CIF {props.config.cif}, y teléfono {props.config.telefono}, es la responsable del tratamiento de sus datos personales
            <br/>
            En la presente política de privacidad se facilita la información sobre el uso que realizará <strong>{props.config.nombreEmpresa}</strong> de sus datos personales en su condición de usuario de la web (la "Web").
            <br/>
            La presente Política de Privacidad tiene por objeto facilitar información sobre los derechos que le asisten en virtud del Reglamento General de Protección de Datos (RGPD) y la Ley Orgánica de Protección de Datos Personales y garantía de los derechos digitales (LOPDGDD). Si tiene cualquier duda relativa al tratamiento de sus datos personales, contacte con <strong>{props.config.nombreEmpresa}</strong> en la siguiente dirección: {props.config.mailContacto}.
            <br/><br/>

            <Typography component="h4">2. Información necesaria y actualizada</Typography>

            Todos los campos que aparezcan señalados con un asterisco (*) en los formularios de la Web serán de obligada cumplimentación, de tal modo que la omisión de alguno de ellos podría comportar la imposibilidad de que se le puedan facilitar los servicios o información solicitados.
            <br/>
            El Usuario de este portal garantiza la autenticidad de todos aquellos datos que comunique y mantendrá la información facilitada permanentemente actualizada de forma que responda, en todo momento, a su situación real. En todo caso, el Usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a <strong>{props.config.nombreEmpresa}</strong> o a terceros con la información que facilite.
            <br/>
            Los datos recabados pasarán a un fichero responsabilidad de <strong>{props.config.nombreEmpresa}</strong> con la única finalidad de gestionar la relación con los Usuarios, así como la prestación de los servicios ofrecidos a través de la Web y, en general, la gestión, desarrollo y cumplimiento de la relación establecida entre <strong>{props.config.nombreEmpresa}</strong> y quienes aporten sus datos personales a través del sitio Web. Adicionalmente, en el caso de que el Usuario haya dado su consentimiento, <strong>{props.config.nombreEmpresa}</strong> tratará los datos para realizar comunicaciones publicitarias o promocionales.
            <br/>
            Durante el proceso de recogida de datos, el Usuario será informado, bien a través de un hipervínculo, o en el propio formulario del portal Web, del carácter obligatorio o no de la cumplimentación de tales datos.
            <br/><br/>

            <Typography component="h4">3. Finalidades de los tratamientos</Typography>

            <strong>{props.config.nombreEmpresa}</strong> recaba sus datos personales para poder gestionar sus solicitudes y ofrecerle los servicios que puedan ser de su interés. En concreto, <strong>{props.config.nombreEmpresa}</strong> trata sus datos personales para las siguientes finalidades:
            <br/>
            a)	Atender a sus consultas, preguntas o solicitudes realizadas a través de los canales de atención o comunicación identificados en la página Web y ponerse en contacto con usted para solucionarlas.
            <br/>
            b)	Mejorar la página Web, así como sus servicios y productos, con tal de ofrecer un mejor servicio y contenido.
            <br/>
            c)	Realizar estudios con fines estadísticos.
            <br/>
            d)	Tener actualizados sus datos.
            <br/>
            e)	Tramitar la solicitud de reservas/ compras / citas.
            <br/>
            f)	Gestionar la facturación y a efectos de contabilidad.
            <br/>
            g)	Cumplir las obligaciones fiscales.
            <br/>
            h)	Gestionar sus datos para enviarle información comercial, en el caso de que haya dado su consentimiento.
            <br/><br/>

            <Typography component="h4">4. Legitimación del tratamiento de sus datos personales</Typography>

            <strong>{props.config.nombreEmpresa}</strong> únicamente recogerá aquellos datos personales que usted desee revelar voluntariamente. De acuerdo con la normativa vigente en materia de protección de datos, así como en materia de servicios de la sociedad de la información y de comercio electrónico, <strong>{props.config.nombreEmpresa}</strong> le comunica que los datos personales aportados en el momento de su registro, o cualquier otro facilitado para su acceso a algunos de los servicios de la página Web, serán incorporados en ficheros de su titularidad.
            <br/>
            Los datos que <strong>{props.config.nombreEmpresa}</strong> puede solicitarle son los siguientes:
            <br/>
            •	Datos de identificación. Información concerniente a una persona física que permite diferenciarla de otras en una colectividad, tales como: nombre y apellidos; número de identificación fiscal; fecha de nacimiento; edad.
            <br/>
            •	Datos de contacto. Información que permite mantener o entrar en contacto con su titular, tal como: domicilio; {props.config.mailContacto}; teléfono fijo; teléfono móvil.
            <br/>
            •	Datos patrimoniales o financieros. Información concerniente a una persona física relativa a sus bienes, derechos, cargas u obligaciones susceptibles de valoración económica, como pueden ser: cuentas bancarias, número de tarjeta de crédito, número de seguridad.
            <br/>

            El tratamiento de sus datos personales puede resultar necesario en ocasiones para dar cumplimiento a determinadas obligaciones legales, o en relación con cualquier relación contractual que <strong>{props.config.nombreEmpresa}</strong> pueda tener con usted.
            <br/>
            En los demás supuestos, y siempre que sea oportuno, <strong>{props.config.nombreEmpresa}</strong> le solicitará su consentimiento para tratar sus datos personales.
            <br/><br/>

            <Typography component="h4">5. Envío de comunicaciones comerciales.</Typography>

            <strong>{props.config.nombreEmpresa}</strong>, en el marco de su actividad promocional, puede facilitarle recursos para el registro o suscripción a noticias o información comercial relacionada con novedades, ofertas y/o nuevos productos o servicios incluidos en la Web. Dichos recursos estarán siempre sujetos a su consentimiento expreso para remitirlos.
            <br/>
            En el supuesto de que lo haya autorizado, <strong>{props.config.nombreEmpresa}</strong> puede enviarle información sobre novedades, ofertas y nuevos productos incluidos en la Web y/o servicios personalizados, de acuerdo con sus gustos y preferencias.
            <br/>
            Podrá revocar su consentimiento a recibir comunicaciones comerciales en cualquier momento, mediante el link o botón habilitado a tal efecto en cada una de las comunicaciones, o directamente comunicando su voluntad de revocar el consentimiento a través de los siguientes canales:
            <br/>
            1.	{props.config.mailContacto} que deberá dirigir a la siguiente dirección: {props.config.mailContacto}
            <br/>
            2.	Correo postal dirigido a <strong>{props.config.nombreEmpresa}</strong>, {props.config.direccionCompleta}.
            <br/><br/>

            <Typography component="h4">6. Decisiones automatizadas y elaboración de perfiles</Typography>

            <strong>{props.config.nombreEmpresa}</strong> recabará su consentimiento expreso para el tratamiento de sus datos de carácter personal con motivo de la posible adopción de decisiones automatizadas y/o la elaboración de perfiles.
            <br/>
            Para la prestación de algunos servicios, <strong>{props.config.nombreEmpresa}</strong> puede tratar sus datos personales utilizando medios automatizados. Ello significa que determinadas decisiones se toman de forma automática, sin intervención humana.
            <br/>
            Otro tratamiento que <strong>{props.config.nombreEmpresa}</strong> puede realizar con sus datos es la elaboración de perfiles, con la finalidad de predecir su comportamiento en la Web. Como resultado de ello se mostrarán en la página principal productos o servicios que puedan ser de su interés, en base a las preferencias que haya demostrado mediante su navegación, o información obtenida a través de las cookies.
            <br/>
            Puede revisar la Política de Cookies aplicable en el correspondiente apartado de esta Web. Asimismo, puede ponerse en contacto con <strong>{props.config.nombreEmpresa}</strong> para cualquier duda o consulta relacionada con el tratamiento de sus datos personales a través de la siguiente dirección: {props.config.mailContacto}.
            <br/><br/>

            <Typography component="h4">7. Duración del tratamiento y plazo de conservación de los datos</Typography>

            <strong>{props.config.nombreEmpresa}</strong> se compromete a tratar sus datos personales mientras realmente sean útiles y necesarios para poder ofrecerle un servicio de calidad mediante su tratamiento. Por ello, <strong>{props.config.nombreEmpresa}</strong> realizará todos los esfuerzos oportunos y razonables para minimizar el plazo de tratamiento y conservación de sus datos personales.
            <br/>
            En este sentido, los datos proporcionados a <strong>{props.config.nombreEmpresa}</strong> se conservarán mientras se mantenga la relación comercial o durante los años necesarios para cumplir con las obligaciones legales.
            <br/>
            En cualquier caso, y aunque usted solicite la eliminación de sus datos personales, <strong>{props.config.nombreEmpresa}</strong> tendrá derecho a conservarlos y mantenerlos, bajo el debido bloqueo, durante el plazo necesario para cumplir con las obligaciones legales que correspondan, así como para su puesta a disposición ante las Autoridades competentes en las distintas materias que sean de aplicación.
            <br/><br/>

            <Typography component="h4">8. Ejercicio de derechos y reclamaciones ante la AEPD</Typography>

            Como consecuencia del tratamiento de sus datos personales, la legislación vigente le otorga una serie de derechos. <strong>{props.config.nombreEmpresa}</strong> le ofrece una explicación resumida de cada derecho, para facilitarle su ejercicio:
            <br/>
            1)	Derecho de acceso: Usted tendrá derecho a conocer los datos personales que son tratados por <strong>{props.config.nombreEmpresa}</strong>, así como las finalidades de dichos tratamientos.
            <br/>
            2)	Derecho de rectificación: Puede solicitar la rectificación de cualquier dato, en cualquier momento, si los que constaran en los registros de <strong>{props.config.nombreEmpresa}</strong> fuesen erróneos.
            <br/>
            3)	Derecho de supresión: Podrá solicitar, en cualquier momento, que sus datos personales sean suprimidos de los ficheros de <strong>{props.config.nombreEmpresa}</strong> Sin embargo, y tal y como ha sido indicado previamente, debe tener en cuenta que, en determinadas circunstancias, el cumplimiento de la legislación vigente puede impedir el efectivo ejercicio de este derecho.
            <br/>
            4)	Derecho de oposición: Usted podrá oponerse al tratamiento de sus datos personales en relación con cualesquiera finalidades con las que tratemos sus datos, de acuerdo a las políticas de privacidad aplicables en cada caso.
            <br/>
            5)	Derecho de limitación del tratamiento: Usted podrá solicitar la limitación del tratamiento de sus datos personales cuando la exactitud, la legalidad o la necesidad del tratamiento de los datos resulte dudosa, en cuyo caso, <strong>{props.config.nombreEmpresa}</strong>, podrá conservarlos para el ejercicio o la defensa de reclamaciones.
            <br/>
            6)	Derecho a la portabilidad de sus datos: Usted tendrá derecho a, siempre que sea técnicamente posible y razonable, solicitar que los datos personales que haya proporcionado directamente sean comunicados a otro responsable del tratamiento. <strong>{props.config.nombreEmpresa}</strong> facilitará directamente sus datos a dicho otro responsable del tratamiento, pero si no fuera posible, se los facilitará a usted en un formato estándar.
            <br/>
            Siempre que <strong>{props.config.nombreEmpresa}</strong> trate sus datos personales con base en su consentimiento expreso, podrá revocarlo en cualquier momento, sin que tenga consecuencias para usted más allá de, según el derecho ejercido, no poder prestarle determinados servicios.
            <br/>
            El ejercicio de los derechos que ostenta podrá hacerse efectivo dirigiéndose a <strong>{props.config.nombreEmpresa}</strong>, indicando qué derecho desea ejercitar, a través de alguno de los siguientes medios, acompañando copia de su DNI o documento identifiprops.configivo equivalente:
            <br/>
            (i)	Mediante {props.config.mailContacto} que deberá dirigir a la siguiente dirección: {props.config.mailContacto}
            <br/>
            (ii)	Correo postal dirigido a <strong>{props.config.nombreEmpresa}</strong>, {props.config.direccionCompleta}.
            <br/><br/>
            En caso de que no quiera ejercer un derecho en concreto, pero tenga la necesidad de realizar una consulta o sugerencia en relación con el tratamiento de sus datos de carácter personal también podrá dirigirse a las direcciones facilitadas para el ejercicio de los derechos.
            <br/>
            Tenga en cuenta que, en todo caso, podrá presentar una reclamación ante la Autoridad Competente en materia de protección de datos, si considera que <strong>{props.config.nombreEmpresa}</strong> no ha tratado sus datos de forma lícita o que no ha atendido correctamente sus solicitudes o derechos. En España, dicha Autoridad Competente es la Agencia Española de Protección de Datos.
            <br/><br/>

            <Typography component="h4">9. Confidencialidad y seguridad en el tratamiento de datos</Typography>

            Sus datos personales son muy importantes para <strong>{props.config.nombreEmpresa}</strong>, por ello se compromete a tratarlos con la máxima confidencialidad y discreción, así como a implementar todas aquellas medidas de seguridad que considere oportunas y razonables.
            <br/>
            En este sentido, <strong>{props.config.nombreEmpresa}</strong> declara y garantiza que ha establecido todos los medios técnicos a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos facilitados por los usuarios. Asimismo, <strong>{props.config.nombreEmpresa}</strong> revisa constantemente sus medidas de seguridad para asegurar que se encuentren siempre al día.
            <br/><br/>

            <Typography component="h4">10. Redes sociales</Typography>

            <strong>{props.config.nombreEmpresa}</strong> ha creado distintos perfiles en redes sociales para que cualquier usuario pueda unirse a ellas. Sin embargo, debe tener en cuenta que, salvo que le solicitemos sus datos directamente a través de acciones de marketing, concursos, promociones o cualquier otra forma válida, sus datos pertenecerán a la Red Social correspondiente. Por lo tanto, es conveniente que lea detenidamente las condiciones de uso y políticas de privacidad de cada red social, y se asegure de configurar sus preferencias en cuanto al tratamiento de sus datos.
            <br/><br/>

            <Typography component="h4">11. Cookies</Typography>

            La Web únicamente utilizará dispositivos de almacenamiento y recuperación de datos ('Cookies') cuando el usuario haya dado su consentimiento previo para ello, de acuerdo con lo que se indica en la ventana emergente del navegador del usuario cuando accede por primera vez a la Web, y en los demás términos y condiciones que se indican en la Política de Cookies que todo usuario debe conocer.


          </Container>
          }
        </Container>
      </div>
    </React.Fragment>);
}

export default Landing;
