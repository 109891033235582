import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  deco: {
    backgroundColor: '#1e5262',
    width: 1050,
    height: 1050,
    borderRadius: '50%',
    position: 'absolute',
    left: -300,
    top: -50,
    opacity: 1
  },
  root: {
    position: 'relative',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        padding: 0
      }
    },
    '& nav': {
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        overflow: 'auto',
      }
    }
  },
  sideFilter: {
    '& nav': {
      margin: '0.5rem 0 1rem 0',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 3)
      }
    }
  },
  filter: {
    borderRadius: '40px !important',
    textTransform: 'capitalize',
    color: 'white !important',
    marginBottom: theme.spacing(),
    padding: theme.spacing(0.5, 2),
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      overflow: 'hidden'
    },
    [theme.breakpoints.down('sm')]: {
      '& > div': {
        textAlign: 'center'
      }
    }
  },
  active: {
    background: `${theme.palette.primary.light} !important`,
    color: theme.palette.primary.dark,
  },
  massonry: {
    position: 'relative',
    margin: theme.spacing(0, 4),
    '& button': {
      width: '100%'
    }
  },
}));

export default useStyles;
