import React from 'react';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import useStyles from './cards-style';
import { TextValidator,ValidatorForm } from 'react-material-ui-form-validator';
import { workWithLocalStorage } from "../../../auxiliares/funciones";
import Swal from 'sweetalert2';
import CONFIG from '../../../CONFIG.json';

function Services(props) {
  const classes = useStyles();  
  const qr = window.location.pathname.split('/')[2]
  const { config } = props;
  const [ codigo, setCodigo ] = React.useState('');
  const cliente = workWithLocalStorage('get','cliente',null)
  const select = workWithLocalStorage('get','select',null)
  if(cliente.personas !== 0){
    select.moneyT = (select.money * cliente.personas);
    workWithLocalStorage('set','select',select);
  }

  const handleChange = event => {
    setCodigo(event.target.value);
  };

  function pintaResumen(){
    switch(config.tipoReserva) {
      case '3':
        return <Typography component="p">
                  Se mandará un email al local para que te confirmen vía <b>mail/teléfono</b> la cita en la fecha y la hora solicitada
               </Typography>;
      case '4':
        return <Typography component="p">
                Para hacer la reserva deberás abonar el 20% <b>({select.moneyT ? select.moneyT : select.money} €)</b> del importe del servicio.<br/>
                A continuación podrás proseguir con el pago de la reserva.
               </Typography>;
      case '2':
        return <Typography component="p">
                Para hacer la reserva deberás abonar el 50% <b>({select.moneyT ? select.moneyT : select.money} €)</b> del importe del servicio.<br/>
                A continuación podrás proseguir con el pago de la reserva.
               </Typography>;
      case '1':
         return <Typography component="p">
                 Para hacer la reserva deberás abonar el importe completo del servicio <b>({select.moneyT ? select.moneyT : select.money} €)</b>.<br/>
                 A continuación podrás proseguir con el pago de la reserva.
                </Typography>;
      default: <></>;
    }
  }

  function EnviarTicketCita(id){
    //console.log(id)
    fetch(CONFIG.api + "Reservas?Action=enviarTicketCita&qr=" + qr+"&id="+id).then(res => res.json())
    .then(async (result) => {
      if (result.Error) {
        Swal.fire({
           title: result.Error,
           icon: 'error',
           confirmButtonText: 'Vale'
        });
      }
    });
  }

  function comprobarDescuento(){
    props.setLoading(true);
    let form = { token: null }
    const cliente = workWithLocalStorage('get','cliente',null);
    const select = workWithLocalStorage('get','select',null);
    delete select.color
    Object.keys(cliente).forEach((key, index) => {
        form[key]=cliente[key];
    });
    Object.keys(select).forEach((key, index) => {
        form[key]=select[key];
    });
    var formT = Object.keys(form).map(k => k + '=' + form[k]).join('&');
    fetch(CONFIG.api + "Reservas?Action=stripeReserva&codigo="+codigo+"&qr=" + qr+"&"+formT)
      .then(res => res.json())
      .then((result) => {
        if (result.Success) {
            //props.props.setPagado(true);
            //elements.getElement(CardElement).clear();
            props.setPagado(true);
            //elements.getElement(CardElement).clear();
            EnviarTicketCita(result.id)            
            props.setLoading(false);
          
        }else{
            props.setLoading(false);
            Swal.fire({
              title: result.Error,
              icon: 'error',
              confirmButtonText: 'Vale'
            });
        }
      })
  }

  return (
    <Box className={classes.defaultCard}>
      <div className={classes.text}>
        <Typography display="block" variant="h6">Resumen de reserva</Typography>
        <Typography component="p">
          <strong>Nombre: </strong>{cliente.nombre}<br/>
          <strong>Email: </strong>{cliente.email}<br/>
          <strong>Teléfono: </strong>{cliente.telefono}<br/>
          <strong>Notas: </strong>{cliente.notas}<br/>
          {cliente.personas !== 0 &&
          <><strong>Personas: </strong>{cliente.personas}</>
          }
        </Typography>
        { pintaResumen() }
        { !props.pagado ?
        <Typography component="p">
          <ValidatorForm onSubmit={()=>{}}>
            <Button variant="contained" color="secondary" className={`${classes.button} fondoRosa fondoRosaHover`} 
              style={{ width: '120px'}} onClick={()=>{comprobarDescuento()}}>
                Comprobar
                </Button>
            <TextValidator
              className={classes.input}
              type="text"
              label="Código descuento"
              onChange={handleChange}
              name="codigo"
              value={codigo}
            />
          </ValidatorForm>
        </Typography>
        : <Typography component="p" style={{color: 'green' , marginTop: '5px'}}>
            Reserva realizada con éxito.<br/>Recibirá un email con los pasos a seguir.
          </Typography>
        }
      </div>
    </Box>
  );
}

export default Services;
