import React, { useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { workWithLocalStorage } from "../../../../auxiliares/funciones";
import CircularProgress from '@mui/material/CircularProgress';
import CreditScore from '@mui/icons-material/CreditScore';
import useStyles from '../../Cards/cards-style';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CONFIG from '../../../../CONFIG.json';
import Swal from 'sweetalert2';

// const stripePromise = workWithLocalStorage('get','sp',null) ? loadStripe(workWithLocalStorage('get','sp',null).key) : null;
const qr = window.location.pathname.split('/')[2]

const CheckoutForm = (props) => {
  const classes = useStyles();
  // const stripe = useStripe();
  // const elements = useElements();
  const [errorMsg,setErrorMsg] = useState('');

  function DeshacerCita(id){
    fetch(CONFIG.api + "Reservas?Action=deshacerCita&qr=" + qr+"&id="+id);
  }

  function EnviarTicketCita(id){
    //console.log(id)
    fetch(CONFIG.api + "Reservas?Action=enviarTicketCita&qr=" + qr+"&id="+id).then(res => res.json())
    .then(async (result) => {
      if (result.Error) {
        Swal.fire({
           title: result.Error,
           icon: 'error',
           confirmButtonText: 'Vale'
        });
      }
    });
  }

  /*const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    props.props.setPaying(true);
    props.props.setLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      const { id } = paymentMethod;
      try {
        let form = { token: id }

        const cliente = workWithLocalStorage('get','cliente',null);
        const select = workWithLocalStorage('get','select',null);
        delete select.color
        Object.keys(cliente).forEach((key, index) => {
            form[key]=cliente[key];
        });
        Object.keys(select).forEach((key, index) => {
            form[key]=select[key];
        });
        var formT = Object.keys(form).map(k => k + '=' + form[k]).join('&');
        fetch(CONFIG.api + "Reservas?Action=stripeReserva&qr=" + qr+"&"+formT)
          .then(res => res.json())
          .then((result) => {
            if (result.Success) {
                //props.props.setPagado(true);
                //elements.getElement(CardElement).clear();
                setErrorMsg('');
                stripe.confirmCardPayment(result.id2,{
                  payment_method:{
                    card: elements.getElement(CardElement),
                  }
                })
                .then(function(result2) {
                  if (result2.error) {
                    setErrorMsg(result2.error.message);
                    DeshacerCita(result.id)
                  }else{
                    props.props.setPagado(true);
                    //elements.getElement(CardElement).clear();
                    EnviarTicketCita(result.id)
                    props.props.setPaying(false);
                  }
                  props.props.setLoading(false);
                });
            }else{
                props.props.setLoading(false);
                setErrorMsg(result.Error);
            }
            props.props.setPaying(false);
          })
      } catch (error) {
        console.log(error);
      }
    }else{
      props.props.setPaying(false);
      props.props.setLoading(false);
    }
  };*/
  return <></>;
  /*return (
    <form onSubmit={handleSubmit} className={classes.checkoutForm}>
      { !props.props.pagado ?
      <div className={classes.text} style={{height:'90%'}}>
        <Typography component="p" style={{marginTop: '5px'}}>Por favor introduce los datos de tu tarjeta.</Typography>
        <Typography component="div" style={{marginTop: '20px'}}>
          <CardElement />
        </Typography>
        { errorMsg !== '' &&
        <Typography component="p" style={{color: 'red' , paddingTop: '50px'}}>
          {errorMsg}
        </Typography>
        }
        <Typography component="p" style={{position: 'fixed', bottom: '5px', right: '15px'}}>
            <Button type="submit" disabled={props.props.paying} variant="contained" color="secondary" className={`${classes.button} fondoRosa fondoRosaHover`}>
            { props.props.paying ?
              <CircularProgress style={{height: '20px', width: '20px'}} />
              : 'Pagar'
            } <CreditScore style={{marginLeft: '10px'}}/>
            </Button>
        </Typography>
      </div> :
      <Typography component="p" style={{color: 'green' , marginTop: '5px'}}>
        Reserva realizada con éxito.<br/>Recibirá un email con los pasos a seguir.
      </Typography>
      }
    </form>
  );*/
};

/*function App(props) {
  const classes = useStyles();
  return (
    <Elements stripe={stripePromise}>
    <Box className={classes.defaultCard}>
        <CheckoutForm props={props} />
    </Box>
    </Elements> 
  );
}*/


function App(props) {
  const classes = useStyles();
  return (
    <Box className={classes.defaultCard}>
        <CheckoutForm props={props} />
    </Box>
  );
}


export default App;
