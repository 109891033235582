import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {  ThemeProvider,  createTheme } from '@mui/material/styles';
import {  StylesProvider,  jssPreset, makeStyles } from '@mui/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import CssBaseline from '@material-ui/core/CssBaseline';
import withRouter from '../auxiliares/withRouter';
import appTheme from './theme/appTheme';
/* import css vendors */
import './vendors/hamburger-menu.css';
//import './App.css';
import './vendors/top-loading-bar.css';
import './vendors/animate-extends.css';
import './vendors/page-transition.css';
import './vendors/slick/slick.css';
import './vendors/slick/slick-theme.css';
import Web from './web'
import PolPrivacidad from './politica'
import AvLegal from './legal'
import PolCookies from './cookies'
import CONFIG from '../CONFIG.json';
import { isEmpty, getLogoProp, workWithLocalStorage } from "../auxiliares/funciones";
import Notification from './components/Notification';
import Footer from './components/Footer';
import Header from './components/Header';
import HeaderNoMenu from './components/Header/HeaderNoMenu';

let themeType = 'dark';
const sectionMargin = margin => (margin * 20);
const useStyles = makeStyles(theme => ({
  spaceTopShort: {
    marginTop: sectionMargin(2 / 2),
  }
}));

function MyApp(props) {
  const [loading, setLoading] = useState(false);
  const [urlValida, setUrlValida] = useState(null);
  const [fullCat, setFullCat] = useState(null);
  const [theme] = useState({ ...appTheme('fresh', themeType), direction: 'ltr'  });

  const classes = useStyles();

  useEffect(() => {
    workWithLocalStorage('remove','cliente',null)
    workWithLocalStorage('remove','select',null)
    // Set layout direction
    document.dir = 'ltr';
    if (window.location.pathname.split('/').length>2) {
      setLoading(true);
      const qr = window.location.pathname.split('/').length>2 ? window.location.pathname.split('/')[2] : '';
      if (/^[0-9]{1,6}[\D]{4}/i.test(qr.toUpperCase())) {
         fetch(CONFIG.api + "Reservas?Action=getFullConfiguracion&qr=" + qr)
           .then(res => res.json())
           .then((result) => {
            console.log(result);
             if (!isEmpty(result.Configuracion)) {
                 if(result.Configuracion.logoid !== '' ){
                   result.Configuracion.logo = getLogoProp(result.Configuracion)
                 }
                 console.log()
                 if(result.Configuracion.metodoPago === '3'){
                    workWithLocalStorage('set','sp',null)
                    window.location.reload();
                 }else{
                   if(!workWithLocalStorage('get','sp',null)){
                     workWithLocalStorage('set','sp',{key:result.Configuracion.stripePkToken})
                     window.location.reload();
                   }
                 }
                 setFullCat(result)
                 setUrlValida(1)
                 setTimeout(function(){setLoading(false)},2000)
             }else{
               setUrlValida(-1)
               //window.location = CONFIG.protocolo + '://' +  CONFIG.url_basic+window.location.pathname+'?e=1'
             }
           })
         } else {//subdominio formato incorrecto
           setUrlValida(-2)
           //window.location = CONFIG.protocolo + '://' +  CONFIG.url_basic+window.location.pathname
         }
   }else{//No tiene subdominio
     setUrlValida(0)
   }
},[]);

  const muiTheme = createTheme(theme);
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  let path = '';
  let component = null;
  let header = null;
  if(urlValida === 1){
    let fields = window.location.pathname.split('/');
    if(fields.length === 1){
      path=fields[0];
    }else{
      path=fields[fields.length-1];
    }
    switch(path) {
      case "politica-privacidad":
        component = <PolPrivacidad config={fullCat.Configuracion}/>;
        header = <HeaderNoMenu config={fullCat.Configuracion}/>;
        break;
      case "aviso-legal":
        component = <AvLegal config={fullCat.Configuracion}/>;
        header = <HeaderNoMenu config={fullCat.Configuracion}/>;
        break;
      case "politica-cookies":
        component = <PolCookies config={fullCat.Configuracion}/>;
        header = <HeaderNoMenu config={fullCat.Configuracion}/>;
        break;
      default:
        component = <Web fullCat={fullCat} setLoading={setLoading}/>;
        header = <Header config={fullCat.Configuracion}/>;
        break;
    }
  }

  return (
    <div>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />
          {loading &&
          <div id="preloader">
              <div id="status">
                  <div className="spinner-chase">
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                      <div className="chase-dot"></div>
                  </div>
              </div>
          </div>
          }
          { urlValida < 0 ?
              <Navigate to={'/?e=1'}/> //subdominio formato incorrecto o qr inválido
              : urlValida === 0 ?
              <Navigate to={'/'}/>
            : urlValida === 1 &&
            <div id="main-wrap">
                {header}
                {component}
                <Notification config={fullCat.Configuracion} />
                <section className={classes.spaceTopShort}>
                  <Footer config={fullCat.Configuracion}/>
                </section>
            </div>
          }
        </ThemeProvider>
      </StylesProvider>
    </div>
  );
}
export default withRouter(MyApp);
