import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import LangIcon from '@mui/icons-material/Language';
import InputAdornment from '@mui/material/InputAdornment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Facebook from '@mui/icons-material/Facebook';
import Twitter from '@mui/icons-material/Twitter';
import Instagram from '@mui/icons-material/Instagram';
import Youtube from '@mui/icons-material/YouTube';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
//import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import { useTextAlign } from '../../theme/common';
import useStyles from './footer-style';

function Copyright() {
  return (
    <Typography variant="body2" display="block" color="white">
      &copy;&nbsp;Axigest
    </Typography>
  );
}

function Footer(props) {
  // Theme breakpoints
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const qr = window.location.pathname.split('/').length>2 ? window.location.pathname.split('/')[2] : '';

  const classes = useStyles();
  const align = useTextAlign();

  return (
    <Container maxWidth="lg" component="footer" className={classes.footer}>
      <Grid container spacing={4}>
        {isDesktop && (
        <Grid item xs={12} md={3}>
          <Box >
            <Copyright />
          </Box>
        </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Grid container spacing={isTablet ? 0 : 4} justifyContent="space-evenly" style={ isMobile ? { textAlign: 'center' } : {}}>
              <Grid item xs={12} md={4} className={classes.siteMapItem}>
                <div>
                  <ul>
                    <li>
                      <Link to={'/reservas/'+qr+'/politica-privacidad'} variant="subtitle1" color="textSecondary">
                        Política de privacidad
                      </Link>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className={classes.siteMapItem}>
                <div>
                  <ul>
                    <li>
                      <Link to={'/reservas/'+qr+'/aviso-legal'} variant="subtitle1" color="textSecondary">
                        Aviso legal
                      </Link>
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} md={4} className={classes.siteMapItem}>
                <div>
                  <ul>
                    <li>
                      <Link to={'/reservas/'+qr+'/politica-cookies'} variant="subtitle1" color="textSecondary">
                        Política de cookies
                      </Link>
                    </li>
                  </ul>
                </div>
              </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <div className={classes.socmed}>
            {props.config.twitter !== '' &&
            <IconButton aria-label="TW" className={`${classes.margin} fondoRosa fondoRosaHover`} size="small" onClick={()=>window.open(props.config.twitter)}>
              <Twitter fontSize="inherit" />
            </IconButton>
            }
            {props.config.facebook !== '' &&
            <IconButton aria-label="FB" className={`${classes.margin} fondoRosa fondoRosaHover`} size="small" onClick={()=>window.open(props.config.facebook)}>
              <Facebook fontSize="inherit" />
            </IconButton>
            }
            {props.config.instagram !== '' &&
            <IconButton aria-label="IG" className={`${classes.margin} fondoRosa fondoRosaHover`} size="small" onClick={()=>window.open(props.config.instagram)}>
              <Instagram fontSize="inherit" />
            </IconButton>
            }
            {props.config.youtube !== '' &&
            <IconButton aria-label="YU" className={`${classes.margin} fondoRosa fondoRosaHover`} size="small" onClick={()=>window.open(props.config.youtube)}>
              <Youtube fontSize="inherit" />
            </IconButton>
            }
          </div>
        </Grid>
      </Grid>
      {isMobile && (
        <div className={align.textCenter}>
          <Box p={4}>
            <Copyright />
          </Box>
        </div>
      )}
    </Container>
  );
}

export default Footer;
