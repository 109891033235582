import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    zIndex: 10,
    color: 'white'
  },
  parallaxBottom: {
    '& > div': {
      top: -100,
      overflow: 'visible'
    }
  },
  block: {
    position: 'relative',
    '& > *': {
      [theme.breakpoints.only('sm')]: {
        padding: theme.spacing(4, 2),
        display: 'inline-block',
        width: '49%'
      }
    }
  },
  map: {
    background: '#dedede',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: 250,
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(-6),
      height: 250
    }
  }
}));

export default useStyles;
