import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import { Link } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import useStyles from './notification-style';
import { workWithLocalStorage } from '../../../auxiliares/funciones'

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function Notification(props) {
  const { message } = props;
  const classes = useStyles();
  const cookies = workWithLocalStorage('get','cookies',null)
  const qr = window.location.pathname.split('/').length>2 ? window.location.pathname.split('/')[2] : '';
  const [open, setOpen] = useState(cookies !== undefined ? false : true);
  const handleClose = () => {
    workWithLocalStorage('set','cookies',{cookies: 'yes'})
    setOpen(false);
  };
  return (
    <Snackbar
      TransitionComponent={TransitionUp}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        root: classes.notification,
      }}
      open={open}
    >
      <SnackbarContent
        message={<Typography id="message-id" style={{color: 'white'}}>
          Esta página web usa cookies. Puedes consultar nuestra política de cookies desde este apartado: <Link to={'/reservas/'+qr+'/politica-cookies'} variant="subtitle1" style={{color: '#cd4527'}}>Política de cookies</Link>
        </Typography>}
        classes={{
          action: classes.action
        }}
        action={(
          <Button key="undo" variant="outlined" className={classes.button} onClick={handleClose}>
            Aceptar
          </Button>
        )}
      />
    </Snackbar>
  );
}

export default Notification;
