import React, { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, makeStyles } from '@mui/styles';
import Servicios from './components/AskDoctors';
import Contacto from './components/Clinics';
import { workWithLocalStorage } from "../auxiliares/funciones";

const sectionMargin = margin => (margin * 20);
const useStyles = makeStyles(theme => ({
  mainWrap: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    background: '#043e50',
    color: theme.palette.text.primary,
  },
  spaceBottom: {
    marginBottom: sectionMargin(theme.spacing()),
    [theme.breakpoints.down('md')]: {
      marginBottom: sectionMargin(6)
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: sectionMargin(theme.spacing() / 2)
    }
  },
  spaceTop: {
    marginTop: sectionMargin(theme.spacing()),
    [theme.breakpoints.down('md')]: {
      marginTop: sectionMargin(6)
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: sectionMargin(theme.spacing() / 2)
    }
  },
  spaceBottomShort: {
    marginBottom: sectionMargin(theme.spacing() / 2),
  },
  spaceTopShort: {
    marginTop: sectionMargin(theme.spacing() / 2),
  },
  containerWrap: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
    '& > section': {
      position: 'relative'
    }
  }
}));

function Landing(props) {
  const classes = useStyles();
  const [modalReserva, setModalReserva] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(async()=>{
    window.scrollTo(0,0);
  },[]);

  return (
    <React.Fragment>
      <div className={classes.mainWrap}>
        <main className={classes.containerWrap}>
            <section className={isMobile ? classes.spaceTopShort : classes.spaceTop} style={isMobile ? { marginTop: 100 } : {}} id="Servicios">
              <Servicios servicios={props.fullCat.Extras.Servicios} config={props.fullCat.Configuracion} setLoading={props.setLoading} abreModalReserva={setModalReserva}/>
            </section>
            <section className={classes.spaceTopShort} id="Contacto">
              <Contacto config={props.fullCat.Configuracion}/>
            </section>
        </main>
        {/*<Hidden mdDown>
          <PageNav />
        </Hidden>*/}
        {/*<section className={classes.spaceTopShort}>
          <Footer config={props.fullCat.Configuracion}/>
        </section>*/}
      </div>
    </React.Fragment>
  );
}

export default Landing;
