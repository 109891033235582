import CONFIG from '../CONFIG.json';
import { EncryptStorage } from 'encrypt-storage';

export const workWithLocalStorage = (action,key,value) =>{
    if (window.localStorage) {
    const encryptStorage = new EncryptStorage(CONFIG.secretKey, {});
      if(action==='set'){
          encryptStorage.setItem(key, JSON.stringify(value));
          return "";
      }else if(action==='get'){
        const re = encryptStorage.getItem(key)
        return re;
      }else if(action==='remove'){
          encryptStorage.removeItem(key);
          return "";
      }
      /*if(action==='set'){
          localStorage.setItem(key, JSON.stringify(value));
          return "";
      }else if(action==='get'){
          return JSON.parse(localStorage.getItem(key));
      }else if(action==='remove'){
          localStorage.removeItem(key);
          return "";
      }*/
    }
}

export const buildBody = (token,obj) =>{
    return {
            "data": {
                "API_TOKEN":"uj345345krwyfgERGETBERBVetb457345hwertbWRTBRYJRTHER756756GWEGR",
                "obj":obj
            }
          }
    }

  export const getTimeFromSeconds = (time)=>{
      // Hours, minutes and seconds
      var dias = ~~(time / (3600*24));
      var hrs = ~~(time / 3600);
      var mins = ~~((time % 3600) / 60);

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = "";

      if (dias > 0) {
          ret += "" + dias + " dias";
      }else if (hrs > 0) {
          ret += "" + hrs + " horas";
      }else if(mins>0){
          ret += "" + mins + " minutos";
      }
      return ret;
  }

export const htmlToText = (html) =>{
  /*html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
  html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
  html = html.replace(/<\/div>/ig, '\n');
  html = html.replace(/<\/li>/ig, '\n');
  html = html.replace(/<li>/ig, '  *  ');
  html = html.replace(/<\/ul>/ig, '\n');
  html = html.replace(/<\/p>/ig, '\n');
  html = html.replace(/<br>/gi, "\n");
  html = html.replace(/<br\/>/gi, "\n");
  html = html.replace(/<br \/>/gi, "\n");
  html = html.replace(/<[^>]+>/ig, '');
  return html*/

  const { htmlToText } = require('html-to-text');
  return htmlToText(html, {});
}

export const isEmpty = (obj) => {
  if(obj === undefined || obj === null){
    return true;
  }else{
    return Object.keys(obj).length === 0;
  }
}

export const compareArrays = (x,y)=>{
   return x.length === y.length && x.every((o, idx) => compareObjects(o, y[idx]));
}

export const compareObjects = (x,y)=>{
  return typeof x === 'object' && Object.keys(x).length > 0
        ? Object.keys(x).length === Object.keys(y).length
            && Object.keys(x).every(p => compareObjects(x[p], y[p]))
        : x === y;
}

export const actualizaObjeto = (obj,data) =>{
  const keys = Object.keys(data);
  keys.forEach((key, index) => {
    obj[key] = data[key]
  });
  return obj
}

export const getProfile = () =>{
  const user= getLoggedInUser()
  let profile = user.token.charAt(0)
  return parseInt(profile)
}

export const getLoggedInUser = () => {
        if(!workWithLocalStorage('get','user','')) return null;
        return workWithLocalStorage('get','user','');
}

export const isNumber = value => {
    if (isNaN(value)) {
        return false
    } else {
        return true
    }
}

export const isEmail = value => {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (value.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}

//is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

export const showLoader = (mostrar) =>{
  if(mostrar)
    {
      document.getElementById('preloader').style.display = "block";
      document.getElementById('status').style.display = "block";
    }else{
      document.getElementById('preloader').style.display = "none";
      document.getElementById('status').style.display = "none";
    }
}

export const getIndividualItemArray = (array, field) => {
  let arrayF = [];
  array.map(single => {
    return arrayF.push(single[field]);
  })
  let individualItemArray = arrayF.filter(function(v, i, self) {
    return i === self.indexOf(v);
  });
  return individualItemArray;
};

export const getURLImageCata = (qr,id,idRef,carpeta) =>{
    return CONFIG.api+'Reservas?Action=DescargarFichero&qr='+qr+'&id='+id+'&idRef='+idRef+'&carpeta='+carpeta;
}

export const getLogoProp = (config) => {
    if(config.logoid !== ''){
      const url = getURLImageCata(config.qr,config.logoid,config.id,'reservas/'+config.id+'/logo')
      return url
    }else{
      return null
    }
};

export const getImagenesServicio = (config,servicio) => {
    if(servicio.ficheroid !== ''){
      const url = getURLImageCata(config.qr,servicio.ficheroid,servicio.id,servicio.carpeta);
      return url;
    }else{
      return null;
    }
  };
