import React, { Component } from 'react';
import { authProtectedRoutes, publicRoutes } from "./rutas/";
import { isUserAuthenticated, getProfile } from './auxiliares/funciones';
import { Routes,Route } from "react-router-dom";
import AppRoute from "./rutas/rutas";
import Page404 from "./paginas/404";
import Reservas from './reservas'
import ("./reservas/App.css");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    /*const path = window.location.pathname;
    if (path.indexOf('reservas') >= 0) {
      import("./reservas/App.css");
    } else if (path.indexOf('catalogo') >= 0) {
      import("./catalogo/style.css");
    } else {
      //import("./styles.css");
    }*/
  }

  render() {
    let profileID = 0
    if(isUserAuthenticated()){
      profileID = getProfile()
    }

      return (
            <React.Fragment>

            {publicRoutes.map((route, idx) => {
                  return (
                    <AppRoute
                      path={route.path}
                      component={route.component}
                      key={idx}
                      isAuthProtected={false}
                      exact
                    />
                  )
                })
              }
              {authProtectedRoutes.filter(route => route.profiles.includes(profileID) ).map((route, idx) => {
                  return route.exact !== undefined ?
                  <AppRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                    exact
                  />
                  :<AppRoute
                    path={route.path}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                  />
                })
              }
                <AppRoute  component={Page404} />
            </React.Fragment>
    );
}
}

export default App;
