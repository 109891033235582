import React, { Component } from "react";
import Swal from 'sweetalert2';
import { useParams  } from "react-router-dom";
import CONFIG from '../CONFIG.json';
import Layout from '../plantillas/generica'
import { isEmpty, workWithLocalStorage, showLoader } from "../auxiliares/funciones";
import withRouter from '../auxiliares/withRouter';

class Principal extends Component {

  CompruebaQR = (qr) => {
    //[0-9]{1,6}[\D]{4} => 123AAAA
    if(qr === ''){
      qr=document.getElementById('qrcode').value
    }
    if (/^[0-9]{1,6}[\D]{4}/i.test(qr.toUpperCase())) {
      showLoader(true);
      fetch(CONFIG.api+"Reservas?Action=getConfiguracion&qr="+qr)
      .then(res => res.json())
      .then((result) => {
        //this.toggleLoading()
        if( !isEmpty(result)){
              window.location = CONFIG.protocolo+'://'+CONFIG.url_basic+window.location.pathname+'reservas/'+qr
        }else{
          showLoader(false);
          Swal.fire({
             title: 'Introduce un código válido',
             icon: 'error',
             confirmButtonText: 'Vale'
          });
        }
      })
    }else{
      Swal.fire({
         title: 'Introduce un código válido',
         icon: 'error',
         confirmButtonText: 'Vale'
      });
    }
  }

    componentDidMount(){
      document.body.classList.add('backgroundImage');
      document.body.style.background = "url('../../../assets/img/background.jpg') no-repeat center center fixed";
      document.body.style.backgroundSize = "cover";
      const qr = window.location.pathname.split('/').length>2 ? window.location.pathname.split('/')[2] : '';
      if (qr !== '') {
        if (/^[0-9]{1,6}[\D]{4}/i.test(qr.toUpperCase())) {
          this.CompruebaQR(qr);
        }else{
          showLoader(false);
        }
      }else{
        showLoader(false);
      }
    }

    render(){
      workWithLocalStorage('remove','reservas','');
      return (
        <Layout>
          <div className="login-register-area pt-100 pb-100">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-12 ml-auto mr-auto">
                  <div className="login-register-wrapper">
                      <div className="login-form-container">
                      <h4><img alt="logo" className="logo-100" src={process.env.PUBLIC_URL + "/assets/img/logo_big.png"} /></h4>
                        <div className="login-register-form">
                          <form>
                            <input type="text" id="qrcode" name="qr" placeholder="Código - Por ejemplo: 0AAAA"/>
                            <div className="button-box">
                            { window.location.search === '?e=1' &&
                              <label className="ml-10 error">Código de establecimiento incorrecto</label>
                            }
                              <div className="login-toggle-btn">
                                <label className="ml-10">Por favor introduce el código que le habrá facilitado el establecimiento</label>
                              </div>
                              <button type="button" className="boton" onClick={() => {this.CompruebaQR('')}}>
                                <span>Acceder</span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      );
    }
};

export default withRouter(Principal);
