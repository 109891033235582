import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  mainWrap: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    background: '#043e50',
    color: theme.palette.text.primary,
  },
  containerWrap: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(10),
    },
    '& > section': {
      position: 'relative'
    }
  },
  body:{
    background: '#fff',
    padding: '30px',
    boxShadow: '0px 3px 5px -1px rgb(50 50 50 / 20%), 0px 6px 10px 0px rgb(50 50 50 / 14%), 0px 1px 18px 0px rgb(50 50 50 / 12%)'
  },
  title:{
    textAlign: 'center',
    fontSize: '2.5rem !important',
    fontWeight: '900 !important',
    color: 'white',
    marginBottom: '15px !important'
  }
}));

export default useStyles;
