import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import ScrollAnimation from 'react-scroll-animation-wrapper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AddressCard from '../Cards/Address';
import useText from '../Title/title-style';
import useStyles from './clinics-style';

function MapAdress(props) {
  // Theme breakpoints
  const theme = useTheme();
  const text = useText();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container fixed>
        <Grid container spacing={6}>
          {isDesktop && (
            <Grid item lg={1} />
          )}
          <Grid item lg={12} md={12} xs={12}>
            <Box mt={5}>
              <Typography variant="h4" className={text.primary}>
                {props.config.nombre}
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={5} md={6} xs={12} style={{ paddingTop: 0}}>
            <div className={classes.block}>
                <ScrollAnimation
                  animateOnce
                  animateIn="fadeInLeftShort"
                  offset={-30}
                  delay={1 * 200}
                  duration={0.3}
                >
                  <div>
                    <AddressCard
                      phone={props.config.telefono}
                      email={props.config.mail}
                      address={props.config.direccion}
                      config={props.config}
                    />
                  </div>
                </ScrollAnimation>
            </div>
          </Grid>
          <Grid item md={6} xs={12} style={{ paddingTop: 0}}>
            {props.config.gmap !== '' &&
            <Paper className={classes.map} elevation={10} style={{ marginTop: 50}}>
              <iframe src={props.config.gmap} style={{border: 0, width: '100%', height: 250}} allowFullScreen="" loading="lazy"></iframe>
            </Paper>
            }
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default MapAdress;
