import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useStyles from './cards-style';
import Swal from 'sweetalert2';
import { capitalize, timeFromDate, getTimeFromSeconds, datetimeToString } from "../../../auxiliares/fechas";

function Services(props) {
  const classes = useStyles();
  const { img,icon,title,desc,money,time,select, precioPorPersona } = props;
  let hora = 'Selecciona una hora'
  let fecha = 'Selecciona una fecha'
  if(select){
    fecha = capitalize(select.toLocaleDateString('es-ES',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }))
    hora = 'de ' + timeFromDate(datetimeToString(select)) + ' a '
    var h = new Date(select.getTime()+(time*60*1000))
    hora += timeFromDate(datetimeToString(h))
  }

  return (
    <Box className={classes.defaultCard}>
      <div className={classes.text}>
        <Typography display="block" variant="h6">{title}</Typography>
        <Typography component="p">{desc}</Typography>
        <Typography component="p">
          <b>Duración:</b> {getTimeFromSeconds(time*60)}<br/>
          <b>Precio:</b> {money} € {precioPorPersona === '1' && 'por persona'}
        </Typography>
        <Typography component="p">
          <b>Fecha seleccionada:</b><br/>
          <Typography component="span" color="#e38e7b !important">{fecha}</Typography><br/>
          <b>Hora seleccionada:</b><br/>
          <Typography component="span" color="#e38e7b !important">{hora}</Typography>
        </Typography>
      </div>
    </Box>
  );
}

export default Services;
