import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Scrollspy from 'react-scrollspy';
import Settings from './Settings';
import MobileMenu from './MobileMenu';
import useStyles from './header-style';
import navMenu from './menu';

let counter = 0;
function createData(name, url, offset) {
  counter += 1;
  return {
    id: counter,
    name,
    url,
    offset,
  };
}

function Header(props) {
  // Theme breakpoints
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const qr = window.location.pathname.split('/').length>2 ? window.location.pathname.split('/')[2] : '';

  const [fixed, setFixed] = useState(false);
  let flagFixed = false;
  const handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagFixed = (scroll > 80);
    if (flagFixed !== newFlagFixed) {
      setFixed(newFlagFixed);
      flagFixed = newFlagFixed;
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);
  const classes = useStyles();
  const {
    invert,
    t
  } = props;
  const [menuList] = useState([
    createData(navMenu[0], '#' + navMenu[0], 200),
    createData(navMenu[1], '#' + navMenu[1], 200),
  ]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <Fragment>
      <AppBar
        component="div"
        position="relative"
        id="header"
        className={clsx(
          classes.header,
          fixed && classes.fixed,
          openDrawer && classes.openDrawer
        )}
      >
        <Container fixed={isDesktop}>
          <div className={classes.headerContent}>
            <nav className={clsx(classes.navLogo, invert && classes.invert)}>
              <div className={classes.logo}>
                {invert ? (
                  <Link to={'/reservas/'+qr}>
                    <a>
                      {props.config.logoid !== '' && <img src={props.config.logo} alt="logo" />}
                      {props.config.nombre}
                    </a>
                  </Link>
                ) : (
                  <Link to={'/reservas/'+qr}>
                    {props.config.logoid !== '' && <img src={props.config.logo} alt="logo" />}
                    {props.config.nombre}
                  </Link>
                )}
              </div>
            </nav>
            {/*<nav className={clsx(classes.navMenu, invert && classes.invert)}>
              <Settings invert={invert} />
            </nav>*/}
          </div>
        </Container>
      </AppBar>
    </Fragment>
  );
}

Header.defaultProps = {
  invert: false
};

export default Header;
