import React, { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Container, Typography} from '@mui/material';
import { useTheme } from '@mui/styles';
import useStyles from './theme/otherPages';

function Landing(props) {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  return (
    <React.Fragment>
      <div className={classes.mainWrap}>
        <Container className={classes.containerWrap}>
          <Typography component="h1" className={classes.title}>Política de cookies</Typography>
          { props.config.cookies.length > 50 ?
          <Container className={classes.body} dangerouslySetInnerHTML={{ __html: props.config.cookies }}></Container>
          : <Container maxWidth="md" className={classes.body}>

          Este sitio web utiliza cookies y/o tecnologías similares que almacenan y recuperan información cuando navega, con la finalidad de poder recabar información acerca del uso del referido sitio.
          <br/>
          En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, facilitar su navegación a través del sitio web, reconocerle como usuario, obtener información sobre sus hábitos de navegación o personalizar la forma en que se muestra el contenido, proporcionarle una mejor experiencia en el uso del mismo, e identificar problemas para mejorar nuestro sitio web. Asimismo, en caso de que preste su consentimiento, utilizaremos cookies que nos permitan obtener más información acerca de sus preferencias y personalizar nuestro sitio web de conformidad con sus intereses individuales.
          <br/>
          La presente política de cookies tiene por finalidad informarle de manera clara y precisa sobre las cookies que se utilizan en nuestro sitio web.
          <br/><br/>
          <Typography component="h4">1.	Definición y función de las cookies</Typography>

          Una cookie es un archivo que se descarga en el equipo del usuario (ordenador o dispositivo móvil) con la finalidad de almacenar datos que podrán ser actualizados y recuperados por la entidad responsable de su instalación.
          <br/>
          La información recabada a través de las cookies puede incluir la fecha y hora de visitas al sitio web, las páginas visionadas, el tiempo que ha estado en nuestro sitio web y los sitios visitados justo antes y después del mismo. En ningún caso las cookies podrían dañar su equipo.
          <br/>
          Este portal web utiliza cookies estrictamente necesarias y esenciales para que el Usuario utilice la página web y pueda moverse libremente, utilizar áreas seguras, opciones personalizadas, …
          <br/><br/>
          <Typography component="h4">2.	Tipo de cookies y su finalidad</Typography>

          	Según la entidad que las gestione:
          <br/>
          Cookies propias: Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor o desde el que se presta el servicio solicitado por el usuario.
          <br/>
          Cookies de terceros: Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos a través de las cookies.
          <br/><br/>
          	Según su finalidad:
          <br/>
          Cookies técnicas: Las cookies técnicas son aquellas imprescindibles y estrictamente necesarias para el correcto funcionamiento de un portal web y la utilización de las diferentes opciones y servicios que ofrece. Por ejemplo, las que sirven para el mantenimiento de la sesión, la gestión del tiempo de respuesta, rendimiento o validación de opciones, utilizar elementos de seguridad, compartir contenido con redes sociales, etc.
          <br/>
          Cookies de preferencias o personalización: Estas cookies permiten al usuario especificar o personalizar algunas características de las opciones generales de la página web. Por ejemplo, definir el idioma, configuración regional o tipo de navegador.
          <br/>
          Cookies de análisis o medición: Son las utilizadas por el portal web para elaborar análisis del comportamiento de los usuarios en los sitios web y perfiles de navegación, así como para conocer las preferencias de los usuarios con el fin de mejorar la oferta de productos y servicios. Por ejemplo, mediante una cookie analítica se controlarían las áreas geográficas de mayor interés de un usuario, cuál es el producto de más aceptación, etc.
          <br/>
          Cookies de publicidad comportamental: Son aquellas que almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.
          <br/>
          Cookies de redes sociales externas: Para facilitar que el usuario pueda compartir contenidos de la página web en distintas redes sociales se han incluido botones específicos. Estos botones pueden suponer que la red social a la que enlacen instalen cookies que, no estando bajo el control de este portal web, pueden tener la finalidad de gestionar el inicio de sesión del usuario en la red social, el mantenimiento de dicha sesión, o mostrar el contador sobre cuantas veces se ha compartido un determinado contenido en cada red social. Recomendamos a los usuarios que revisen regularmente las políticas de privacidad y de cookies de cada red social y, en su caso, modifiquen los parámetros de privacidad de cada una de ellas para limitar las finalidades de rastreo que puedan llevar a cabo y ajustar dichos parámetros a sus propios intereses.
          <br/><br/>
          	Según el plazo de tiempo que permanecen activadas:
          <br/>
          Cookies de sesión: Son cookies temporales que permanecen en el archivo de cookies de su navegador hasta que abandone la página web, por lo que ninguna queda registrada en el disco duro del usuario. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión y desaparecen al terminar la sesión.
          <br/>
          Cookies persistentes: Son almacenadas en el disco duro y la web las detecta cada vez que el usuario realiza una nueva visita. Una web permanente posee una fecha de expiración determinada. La cookie dejará de funcionar después de esa fecha. Se utilizan generalmente para facilitar, por ejemplo, los servicios de registro.
          <br/>
          Puede ocurrir que algunas Cookies utilizadas en esta web no estén relacionadas con NOMBRE EMPRESA, S.L. Ello se debe a que algunas páginas de la web tienen insertado contenido procedente de webs de terceras partes. Debido a que el referido contenido procede de otra web, NOMBRE EMPRESA, S.L., no controla la configuración de dichas Cookies. Si Usted quiere cambiar sus preferencias de configuración de Cookies, deberá consultar los sitios web de dichas terceras partes para obtener información.
          <br/>
          Tenga en cuenta que, si acepta cookies de terceros y posteriormente desea eliminarlas, deberá hacerlo desde las opciones de su propio navegador o desde el sistema habilitado por los terceros para ello.
          <br/><br/>
          <Typography component="h4">3.	Forma de desactivar o eliminar las cookies y revocación del consentimiento prestado</Typography>

          Para cumplir con la legislación vigente, tenemos que pedir su permiso para gestionar cookies. Si decide no autorizar el tratamiento indicándonos su no conformidad, sólo usaríamos las cookies técnicas, puesto que son imprescindibles para la navegación por nuestra web. En este caso, no almacenaríamos ninguna cookie.
          <br/>
          Si decide modificar la configuración de su acceso a la página web, debe saber que es posible eliminar las cookies o impedir que se registre esta información en su equipo mediante la modificación de los parámetros de configuración de su navegador.
          <br/>
          Además, puede retirar en cualquier momento el consentimiento relacionado con esta Política de Cookies, eliminando las cookies almacenadas en su equipo a través de los ajustes y configuraciones de su navegador de Internet. Para configurar las cookies, puede hacerlo desde el navegador a través de los siguientes enlaces:
          <br/><br/>
          Google Chrome:<br/>
          <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&&hl=es" rel="noopener noreferrer" target="_blank">https://support.google.com/chrome/answer/95647?co=GENIE.Platform=Desktop&&hl=es</a>
          <br/><br/>
          Mozilla Firefox:<br/>
          <a href="https://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia" rel="noopener noreferrer" target="_blank">https://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia</a>
          <br/><br/>
          Internet Explorer:<br/>
          <a href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies" rel="noopener noreferrer" target="_blank">https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies</a>
          <br/><br/>
          Microsoft Edge:<br/>
          <a href="https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy" rel="noopener noreferrer" target="_blank">https://support.microsoft.com/es-es/help/4468242/microsoft-edge-browsing-data-and-privacy</a>
          <br/><br/>
          Safari:<br/>
          <a href="https://support.apple.com/es-es/HT201265" rel="noopener noreferrer" target="_blank">https://support.apple.com/es-es/HT201265</a>
          <br/><br/>

          La mayoría de navegadores le permite gestionar, en cualquier momento, sus preferencias sobre el uso de las cookies. Puede ajustar su navegador para que rechace o elimine determinadas cookies según su criterio.
          <br/><br/>

          <Typography component="h4">4.	Tratamiento de datos de carácter personal</Typography>

          Los datos podrán ser comunicados a autoridades y organismos públicos, para el cumplimiento de una obligación legal requerida, así como a proveedores de servicios y a terceros necesarios para la gestión y ejecución de las cookies, así como a proveedores de servicios que actúan por nuestra cuenta como encargados del tratamiento.
          <br/>
          El tratamiento de los datos se realiza, con carácter general, por prestadores de servicios ubicados dentro del Espacio Económico Europeo o en países que han sido declarados con un nivel adecuado de protección.
          <br/>
          Si consiente el uso de la información obtenida a través de las cookies para fines de publicidad comportamental, se controlará su navegación para mostrarle publicidad basada en sus intereses a partir de un perfil elaborado en base a dicha navegación.
          <br/>
          Obtenga más información sobre quiénes somos, cómo puede contactarnos y cómo procesamos los datos personales en nuestra Política de Privacidad.
          <br/><br/>

          <Typography component="h4">5.	Cambios en la Política de Cookies </Typography>
          Es posible que se produzcan cambios en esta Política de Cookies, por ello le recomendamos revisar esta política cada vez que acceda a nuestro sitio web con el objetivo de estar adecuadamente informado de cómo y para qué usamos las cookies.


          </Container>
          }
        </Container>
      </div>
    </React.Fragment>);
}

export default Landing;
