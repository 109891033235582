import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import MarkEmailRead from '@mui/icons-material/MarkEmailRead';
import CreditScore from '@mui/icons-material/CreditScore';
import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useStyles from './cards-style';
import Default from './Default';
import Resumen from './Resumen';
import Swal from 'sweetalert2';
import DatosPersona from '../Forms/DatosPersona';
import Calendario from '../Calendario/Calendario';
import StripeCheckoutForm from '../Pago/Stripe/CheckoutForm';
import CircularProgress from '@mui/material/CircularProgress';
import CONFIG from '../../../CONFIG.json';
import { getTimeFromSeconds, workWithLocalStorage, isEmail, isNumber } from "../../../auxiliares/funciones";

function Profile(props) {
  const classes = useStyles();
  const [proceso, setProceso] = useState(0);
  const [stripePago, setStripePago] = useState(false);
  const [pagado,setPagado] = useState(false);
  const [open, setOpen] = useState(props.open);
  const [select, setSelect] = useState(null);
  const [paying, setPaying] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    if (paying) return;
    setOpen(false)
    setProceso(0);
    setSelect(null);
    setPagado(false);
    setStripePago(false);
    workWithLocalStorage('remove','select',null)
    workWithLocalStorage('remove','cliente',null)
  }

  const { id, icon, image, name, title, rating, exp, reservable, mailContact, config, description, precioPorPersona, min, max } = props;

  function masInfo(){
    var subject= "Información sobre el servicio: "+name;
    var body = "Estoy interesado en saber más acerca de este servicio";
    var uri = "mailto:"+config.mailContacto+"?subject=";
    uri += encodeURIComponent(subject);
    uri += "&body=";
    uri += encodeURIComponent(body);
    //window.location = 'mailto:'+config.mailContacto
    window.open(uri);
  }

  function ReservaPorConfirmacion(){
    props.setLoading(true);
    const qr = window.location.pathname.split('/')[2]
    let form = {}
    const cliente = workWithLocalStorage('get','cliente',null);
    const select = workWithLocalStorage('get','select',null);
    delete select.color
    Object.keys(cliente).forEach((key, index) => {
        form[key]=cliente[key];
    });
    Object.keys(select).forEach((key, index) => {
        form[key]=select[key];
    });
    var formT = Object.keys(form).map(k => k + '=' + form[k]).join('&');
    fetch(CONFIG.api + "Reservas?Action=preReserva&qr=" + qr+"&"+formT)
    .then(res => res.json())
    .then(async (result) => {
      props.setLoading(false)
      if (result.Error) {
        Swal.fire({
           title: result.Error,
           icon: 'error',
           confirmButtonText: 'Vale'
        });
      }else{
        Swal.fire({
           title: 'Solicitud enviada, en breves nos pondremos en contacto contigo!',
           icon: 'success',
           confirmButtonText: 'Vale'
        });
        handleClose();
      }
    });
  }

  function validaFormPago(){
    if(config.metodoPago === '1'){
      setProceso(3)
      setStripePago(true)
    }
  }

  function validaFormCli(){
    const values = workWithLocalStorage('get','cliente',null)
    let error = ''
    if(values.email === '' || !isEmail(values.email)){
      error = 'Debes escribir un email correcto'
    }else if(values.nombre === ''){
      error = 'Debes escribir un nombre'
    }else if((values.telefono === '' || !isNumber(values.telefono)) && config.tipoReserva === '3'){
      error = 'Debes escribir un teléfono correcto'
    }else if(!values.politica || values.politica === 'false'){
      error = 'Debes aceptar los términos y condiciones'
    }else if(precioPorPersona === '1'){
      if(values.personas < min && values.personas > max){
        error = `Debeis ser entre ${min} y ${max} personas`
      }
    }
    if(error === ''){
      setProceso(2);
    }else{
      Swal.fire({
         title: error,
         icon: 'error',
         confirmButtonText: 'Vale'
      });
    }
  }

  function muestraLadoDerecho(){
    if(!stripePago){
      if(proceso === 0){
        return  <Calendario config={config} servicioid={id} setLoading={props.setLoading}
                    money={rating.endsWith('.00') ? rating.substring(0,rating.length-3) : rating}
                    time={exp} select={select} setProceso={setProceso} precioPorPersona={precioPorPersona} setSelect={setSelect}/>
      }else if(proceso === 1){
        return <DatosPersona config={config} precioPorPersona={precioPorPersona} min={min} max={max}/>
      }else if(proceso === 2){
        return <Resumen config={config} pagado={pagado} setPagado={setPagado} setLoading={props.setLoading}/>
      }
    }else{
      return  <StripeCheckoutForm  setLoading={props.setLoading} setPaying={setPaying} paying={paying} pagado={pagado} setPagado={setPagado}/>
    }
  }

  function muestraBotonSig(){
      if(!select){
        return <Button disabled={true} variant="contained" color="secondary" className={`${classes.button} fondoRosa fondoRosaHover`}>
                Continuar <AssignmentInd style={{marginLeft: '10px'}}/>
                </Button>
      }else if(proceso === 0){ //Adelante paso 1
        return  <Button variant="contained" color="secondary" className={`${classes.button} fondoRosa fondoRosaHover`}
                      onClick={()=>{setProceso(1)}}>
                Continuar <AssignmentInd style={{marginLeft: '10px'}}/>
                </Button>
      }else if(proceso === 1){ //Adelante paso 2
        return <Button variant="contained" color="secondary" className={`${classes.button} fondoRosa fondoRosaHover`} onClick={()=>{validaFormCli()}}>
                Continuar <Check style={{marginLeft: '10px'}}/>
                </Button>
      }else if(proceso === 2 && config.tipoReserva === '3'){ //Adelante paso 3 - Confirmacion Por Email
        return <Button variant="contained" color="secondary" className={`${classes.button} fondoRosa fondoRosaHover`} onClick={()=>{ReservaPorConfirmacion()}}>
                Confirmar <MarkEmailRead style={{marginLeft: '10px'}}/>
                </Button>
      }else if(proceso === 2 && config.tipoReserva !== '3' && !pagado){ //Adelante paso 3 - Resumen
        return <Button variant="contained" color="secondary" className={`${classes.button} fondoRosa fondoRosaHover`} onClick={()=>{validaFormPago()}}>
                Continuar <CreditScore style={{marginLeft: '10px'}}/>
                </Button>
      }else if(proceso === 2 && config.tipoReserva !== '3' && pagado){ //Adelante paso 3 - Resumen
        return <div></div>
      }else if(proceso === 3 && config.tipoReserva !== '3'){
        return <div></div>
      }
  }

  return (
    <Paper className={`${classes.profile} colorAzul fondoBlanco`}>
      <div className={classes.personBlock}>
        <Avatar className={`${classes.photo} fondoRosa`} style={image === null && icon ==='' ? {opacity: 0} :{}}>
          {image !== null ? <img src={image} alt={name} />
          : icon !== '' && <i className={icon} />
          }
        </Avatar>
        <div className={classes.nameCard}>
          <h4>
            {name}
          </h4>
          <Typography>{title}</Typography>
          <Typography sx={{ fontStyle: 'italic' }}>{description}</Typography>
        </div>
      </div>
      <hr className={classes.divider} />
      <div className={classes.action}>
        <div className={classes.property}>
          <span>
            <i className="ion-ios-thumbs-up" />
            &nbsp;
            {rating.endsWith('.00') ? rating.substring(0,rating.length-3) : rating } € { precioPorPersona === '1' && 'por persona'}
          </span>
          <span>
            <i className="ion-ios-briefcase" />
            &nbsp;
            {getTimeFromSeconds(exp*60)}
          </span>
        </div>
        {reservable === '1' ?
        <Button variant="contained" color="secondary" className={`${classes.button} fondoRosa fondoRosaHover`} onClick={handleOpen}>
          Reservar
        </Button>
        :mailContact !== '' ?
        <Button variant="contained" color="secondary" className={`${classes.button} fondoRosa fondoRosaHover`} onClick={() => masInfo()}>
          + Info
        </Button>
        : ""
        }
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"  className={classes.modalParent}
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.modal}>
          <Box style={{textAlign: 'right', marginTop: '-15px', marginRight: '-15px'}}>
            <IconButton onClick={handleClose}>
                <CloseIcon />
            </IconButton>
          </Box>
          <Grid container>
            <Grid item lg={5} md={5} xs={12}>
              <Default img={image} icon={icon} desc={title} title={name} config={config} precioPorPersona={precioPorPersona}
                       select={select} money={rating.endsWith('.00') ? rating.substring(0,rating.length-3) : rating}
                       time={exp}/>
            </Grid>
            <Grid item lg={7} md={7} xs={12}>
              {muestraLadoDerecho()}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.buttons}>
            { (proceso > 0 && !pagado) &&
              <Button disabled={paying} variant="contained" color="secondary" className={`${classes.buttonLeft} fondoRosa fondoRosaHover`} onClick={()=>{
                  if(proceso === 3){setStripePago(false)} setProceso(proceso-1)}}>
                Atrás
              </Button>
            }
          {muestraBotonSig()}
          </Grid>
        </Box>
      </Modal>
    </Paper>
  );
}

export default Profile;
