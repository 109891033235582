import React, { useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Container, Typography} from '@mui/material';
import { useTheme } from '@mui/styles';
import useStyles from './theme/otherPages';

function Landing(props) {
  const classes = useStyles();
  const theme = useTheme();

  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);

  return (
    <React.Fragment>
      <div className={classes.mainWrap}>
        <Container className={classes.containerWrap}>
          <Typography component="h1" className={classes.title}>Aviso Legal</Typography>
          { props.config.avisoLegal.length > 50 ?
          <Container className={classes.body} dangerouslySetInnerHTML={{ __html: props.config.avisoLegal }}></Container>
          : <Container maxWidth="md" className={classes.body}>

          <Typography component="h4">1.	Condiciones de uso del portal</Typography>

          El acceso al Portal por parte de los Usuarios tiene carácter libre y gratuito. No obstante, la utilización, prestación y/o contratación de los servicios o productos que, en su caso, puedan ser ofrecidos por <strong>{props.config.nombreEmpresa}</strong>. puede estar sujeta a la cumplimentación previa del correspondiente formulario, previa aceptación de las condiciones particulares que resulten de aplicación a los mismos.
          <br/>
          El mero acceso al Portal no implica, en sí mismo, el establecimiento de ningún tipo de vínculo o relación comercial entre <strong>{props.config.nombreEmpresa}</strong>. y el Usuario, salvo cuando se hayan establecido los medios oportunos para ello y el Usuario haya dado cumplimiento a los requisitos que, en su caso, sean establecidos.
          <br/>
          La inclusión en el Portal de información relativa a servicios o productos ofrecidos por <strong>{props.config.nombreEmpresa}</strong>. tiene exclusivamente fines informativos y publicitarios, salvo que sea establecida expresamente otra finalidad.
          <br/>
          Si para la utilización, prestación y/o contratación de algún servicio o producto ofrecido a través del Portal, el Usuario debiera proceder a su registro, éste será responsable de aportar información veraz y lícita, garantizando la autenticidad de todos aquellos datos que introduzca a la hora de cumplimentar los formularios preestablecidos para acceder a los servicios o productos de que se trate. Si como consecuencia del registro, se dotara al Usuario de una contraseña, éste se compromete a hacer un uso diligente y a mantener en secreto la misma. En consecuencia, los Usuarios son responsables de la adecuada custodia y confidencialidad de cualesquiera identificadores y/o contraseñas que le sean suministrados por <strong>{props.config.nombreEmpresa}</strong>., y se comprometen a no ceder su uso a terceros, ya sea de forma temporal o permanente, ni a permitir su acceso a personas ajenas.
          <br/>
          Será responsabilidad exclusiva del Usuario la utilización y/o contratación de los servicios o productos por cualquier tercero ilegítimo que emplee a tal efecto la contraseña a causa de una actuación no diligente o de la pérdida de la misma por el Usuario.
          <br/>
          Es obligación del Usuario notificar de forma inmediata a <strong>{props.config.nombreEmpresa}</strong>., cualquier hecho que permita el uso indebido de los identificadores y/o contraseñas, para que pueda proceder a su cancelación. Mientras no se comuniquen tales hechos, <strong>{props.config.nombreEmpresa}</strong>. quedará eximida de cualquier responsabilidad que pudiera derivarse del uso indebido de los identificadores o contraseñas por terceros no autorizados.
          <br/>
          El acceso, navegación y uso del Portal y, en su caso, el uso o contratación de los servicios o productos que sean ofrecidos a través del mismo, se hace bajo la única y exclusiva responsabilidad del Usuario, por lo que éste se compromete a observar diligente y fielmente cualquier instrucción adicional, impartida por <strong>{props.config.nombreEmpresa}</strong>., o por personal autorizado de la misma, relativa al uso del Portal y sus contenidos.
          <br/>
          Por lo tanto, el Usuario se obliga a usar los contenidos, servicios o productos de forma diligente, correcta y lícita, de conformidad con la legislación vigente.
          <br/>
          Todos los contenidos de la Web, salvo que se indique lo contrario, son titularidad de <strong>{props.config.nombreEmpresa}</strong>. o de terceros que han autorizado su inclusión en la Web.
          <br/><br/>

          <Typography component="h4">2.	Limitación de responsabilidad</Typography>

          Queda expresamente prohibido el uso de este portal con fines ilícitos, lesivos o contrarios al contenido del presente Aviso Legal, de forma que puedan dañar, inutilizar o deteriorar la Web o impedir un disfrute normal por otros Usuarios.
          <br/>
          <strong>{props.config.nombreEmpresa}</strong>. declina cualquier responsabilidad por los daños y perjuicios de cualquier naturaleza que pudieran deberse a la utilización de los servicios, por parte del Usuario, en contra de lo dispuesto en el presente Aviso Legal.
          <br/>
          <strong>{props.config.nombreEmpresa}</strong>. excluye toda responsabilidad que se pudiera derivar de circunstancias fuera del control razonable, tales como errores de los proveedores de servicio en Internet, ataques informáticos, etc.
          <br/>
          <strong>{props.config.nombreEmpresa}</strong>. tampoco responderá por los posibles errores o deficiencias de seguridad que pudieran producirse por la utilización, por parte del Usuario, de medios técnicos (hardware/software) no actualizados o inseguros, así como de los daños, errores o inexactitudes que pudieran derivarse del mal funcionamiento del navegador.
          <br/><br/>

          <Typography component="h4">3.	Propiedad intelectual</Typography>

          Todos los contenidos, textos, imágenes, y códigos fuente de este portal son propiedad de <strong>{props.config.nombreEmpresa}</strong>. o de terceros que a los que se han adquirido los derechos de explotación, y están protegidos por los derechos de Propiedad Intelectual e Industrial.
          <br/>
          El usuario únicamente tiene derecho a un uso privado de los mismos, sin ánimo de lucro, y necesita autorización expresa de <strong>{props.config.nombreEmpresa}</strong>. y del titular de los derechos para modificarlos, reproducirlos, explotarlos, distribuirlos o cualquier derecho perteneciente a su titular.
          <br/>
          El establecimiento de enlaces a este portal no confiere ningún derecho sobre el mismo.
          <br/>
          Está absolutamente prohibida la imitación ya sea total o parcial de este portal.
          <br/><br/>

          <Typography component="h4">4.	Protección de datos</Typography>

          Cuando sea necesario que el Usuario se registre o facilite datos personales para acceder a servicios, solicitar información y/o adquirir servicios o productos, la recogida y el tratamiento de los datos personales se llevará a cabo de conformidad y en cumplimiento con lo dispuesto en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales (LOPDGDD), así como el Reglamento UE 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD).
          <br/>
          El Usuario de este portal garantiza la autenticidad de todos aquellos datos que comunique y mantendrá la información facilitada perfectamente actualizada de forma que responda, en todo momento, a su situación real. En todo caso, el Usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a <strong>{props.config.nombreEmpresa}</strong>. o a terceros por la información que facilite.
          <br/>
          En todo caso, los datos recabados pasarán a un fichero responsabilidad de <strong>{props.config.nombreEmpresa}</strong>. con la única finalidad de gestionar la relación con los Usuarios, así como la prestación de los servicios ofrecidos a través de la Web y, en general, la gestión, desarrollo y cumplimiento de la relación establecida entre <strong>{props.config.nombreEmpresa}</strong>. y quienes aporten sus datos personales a través del sitio Web.
          <br/>
          El Usuario faculta expresamente a <strong>{props.config.nombreEmpresa}</strong>. para que pueda remitirle a su dirección de correo electrónico, o medios de comunicación electrónica equivalentes, cuantas comunicaciones sean pertinentes para la prestación del servicio. En el momento de la recogida de datos, el Usuario tendrá la oportunidad de negarse a que sus datos sean utilizados para el envío de comunicaciones publicitarias o promocionales.
          <br/>
          Cuando los datos personales sean recabados a través del formulario, será necesario que el Usuario aporte, al menos, aquellos marcados con un asterisco, ya que, si no se suministraran estos datos considerados necesarios, <strong>{props.config.nombreEmpresa}</strong>. no podrá gestionar el servicio solicitado.
          <br/>
          Para obtener más información acerca del tratamiento de sus datos personales, diríjase al apartado Política de Privacidad.
          <br/><br/>

          <Typography component="h4">5.	Cookies</Typography>

          En cumplimiento de lo dispuesto en el artículo 22.2 de la LSSI, <strong>{props.config.nombreEmpresa}</strong>. podrá utilizar dispositivos de almacenamiento y recuperación de datos (“cookies”) siempre que el Usuario del portal haya dado su consentimiento previo para ello, de acuerdo con lo que se indica en la ventana emergente del navegador del Usuario cuando accede por primera vez al portal, y en los demás términos y condiciones que se indican en la Política de Cookies de <strong>{props.config.nombreEmpresa}</strong>.
          <br/><br/>

          <Typography component="h4">6.	Modificación del Aviso Legal</Typography>

          <strong>{props.config.nombreEmpresa}</strong>. se reserva el derecho a modificar, en cualquier momento y sin preaviso, el presente Portal, o cualquier aspecto sustancial del mismo, así como las condiciones de operatividad, técnicas o de uso.
          <br/>
          Asimismo, <strong>{props.config.nombreEmpresa}</strong>. se reserva el derecho a modificar, en cualquier momento, los términos de este Aviso Legal. El Usuario quedará obligado automáticamente por el Aviso Legal que se halle vigente en el momento en que acceda a la Web, por lo que deberá revisar regularmente el referido Aviso Legal.
          <br/><br/>

          <Typography component="h4">7.	Legislación</Typography>

          El presente Aviso Legal se rige en todos y cada uno de sus puntos por la legislación española.


          </Container>
          }
        </Container>
      </div>
    </React.Fragment>);
}

export default Landing;
