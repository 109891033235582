import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import Hidden from '@mui/material/Hidden';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Container from '@mui/material/Container';
import SendIcon from '@mui/icons-material/Send';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Checkbox from './Checkbox';
import { useText } from '../../theme/common';
import useStyles from './form-style';
import { workWithLocalStorage } from "../../../auxiliares/funciones";

function Contact(props) {
  const classes = useStyles();
  const text = useText();
  const [values, setValues] = useState(workWithLocalStorage('get','cliente',null) !== undefined ? workWithLocalStorage('get','cliente',null) : {
    nombre: '',
    email: '',
    telefono: '',
    notas: '',
    personas: props.precioPorPersona === '1' ? props.min : 0,
    politica: false
  });

  useEffect(() => {
    ValidatorForm.addValidationRule('isTruthy', value => value);
  });

  /*console.log(workWithLocalStorage('get','cliente',null))
  if(workWithLocalStorage('get','cliente',null) !== undefined){
    setValues(workWithLocalStorage('get','cliente',null))
  }else{
    workWithLocalStorage('set','cliente',values)
  }*/
  workWithLocalStorage('set','cliente',values)

  const handleChange = name => event => {
    let val = values
    val[name] = event.target.value
    setValues({ ...values, [name]: event.target.value});
    workWithLocalStorage('set','cliente',val)
  };

  const handleCheck = event => {
    let val = values
    val.politica = event.target.value
    setValues({ ...values, politica: event.target.checked});
    workWithLocalStorage('set','cliente',val)
  };

  return (
    <div className={classes.formWrap}>
      <Container maxWidth="md">
        <Typography display="block" variant="h6">Datos de contacto</Typography>
        <Typography component="p"><i>Rellena al menos los campos que tienen *.</i></Typography>
        <div className={classes.form}>
          <ValidatorForm onSubmit={()=>{}}>
            <Grid container spacing={6}>
              <Grid item xs={12} className={classes.fila}>
                <TextValidator
                  className={classes.input}
                  label="Nombre*"
                  onChange={handleChange('nombre')}
                  name="nombre"
                  value={values.nombre}
                  validators={['required']}
                  errorMessages={['Este campo es requerido']}
                />
              </Grid>
              <Grid item xs={12} className={classes.fila}>
                <TextValidator
                  className={classes.input}
                  label="Email*"
                  onChange={handleChange('email')}
                  name="email"
                  value={values.email}
                  validators={['required', 'isEmail']}
                  errorMessages={['Este campo es requerido', 'email inválido']}
                />
              </Grid>
              <Grid item xs={12} className={classes.fila}>
                <TextValidator
                  className={classes.input}
                  label={ props.config.tipoReserva === '3' ? "Teléfono*" : "Teléfono" }
                  onChange={handleChange('telefono')}
                  name="telefono"
                  //validators={ props.config.tipoReserva === '3' ? ['required','isNumber'] : ['isNumber']}
                  value={values.telefono}
                  //errorMessages={ props.config.tipoReserva === '3' ? ['Este campo es requerido', 'Solo números'] : ['Solo números']}
                />
              </Grid>
              <Grid item xs={12} className={classes.fila}>
                <TextValidator
                  multiline
                  rows="3"
                  className={classes.input}
                  label="Notas"
                  onChange={handleChange('notas')}
                  name="notas"
                  value={values.notas}
                />
                {props.precioPorPersona === '1' && 
                  <TextValidator
                    className={classes.input}
                    type="number"
                    label="Personas"
                    onChange={handleChange('personas')}
                    name="personas"
                    value={values.personas}
                  />
                }
              </Grid>
            </Grid>
            <div className={classes.btnArea}>
              <FormControlLabel
                control={(
                  <Checkbox
                    validators={['isTruthy']}
                    errorMessages="This field is required"
                    checked={values.politica === 'true' ? true : values.politica}
                    value={values.politica}
                    onChange={(e) => handleCheck(e)}
                    color="primary"
                  />
                )}
                label={(
                  <span>
                    Acepto los terminos y condiciones.
                    <br />
                    <a href="/politica-privacidad">Política de privacidad</a>
                  </span>
                )}
              />
            </div>
          </ValidatorForm>
        </div>
      </Container>
    </div>
  );
}

export default Contact;
