import React, { Component, createRef } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid';
import esLocale from '@fullcalendar/core/locales/es';
import Box from '@mui/material/Box';
import CONFIG from '../../../CONFIG.json';
import { isEmpty, workWithLocalStorage } from "../../../auxiliares/funciones";
import { timeBetweenDates, compareDates, dateToStringDate, isBetweenDates, getTimeFromSeconds, datetimeToStringDatetime } from "../../../auxiliares/fechas";
import Swal from 'sweetalert2';

export default class Calendario extends Component {
  constructor(props) {
    super(props);
    const selectEvent = this.compruebaPropsSelect(props)
    this.state = {
      events: [],
      servicio: props.servicioid,
      time: parseInt(props.time),
      selectEvent: selectEvent
    }
  }

  compruebaPropsSelect = props =>{
    const { select, time, money, servicioid } = props;
    if(select !== null){
      var end = new Date(select.getTime()+(parseInt(time)*60*1000))
      const selectEvent = { id: 0 ,start: datetimeToStringDatetime(select), end: datetimeToStringDatetime(end), title: 'Seleccionado', color: '#e38e7b',
       serviceid: servicioid, money: money }
      return selectEvent
    }else{
      return {}
    }
  }

  seleccionaFecha = date =>{
    this.props.setSelect(date)
    var end = new Date(date.getTime()+(this.state.time*60*1000))
    const precio = this.props.config.tipoReserva === '2' ? this.props.money/2 : ( this.props.config.tipoReserva === '4' ? this.props.money*0.2 : this.props.money)
    const selectEvent = { id: 0 ,start: datetimeToStringDatetime(date), end: datetimeToStringDatetime(end), title: 'Seleccionado', color: '#e38e7b',
      serviceid: this.state.servicio, money: precio }
    //events.push(selectEvent)
    //this.setState({selectEvent: selectEvent})
    this.borraSeleccionado()
    this.calendarRef.current.getApi().addEvent(selectEvent)
    workWithLocalStorage('set','select',selectEvent)
  }

  borraSeleccionado = () =>{
    if(this.calendarRef.current.getApi().getEventById(0)){
      this.calendarRef.current.getApi().getEventById(0).remove()
    }
  }

  calendarRef = React.createRef();

  handleDateClick = (select) => { // bind with an arrow function
    if(this.calendarRef.current.getApi().currentDataManager.data.currentViewType !== 'timeGridDay'){
      this.calendarRef.current.getApi().changeView('timeGridDay', select.date)
      this.setState({events:[this.state.selectEvent]})
    }else{
      if(!select.jsEvent.target.classList.contains('fc-non-business')){
        if(select.date.getTime()<new Date().getTime()){
          Swal.fire({
             title: 'No puedes seleccionar una fecha pasada',
             icon: 'error',
             confirmButtonText: 'Vale'
          });
        }else{
          this.borraSeleccionado()
          const dif1 = timeBetweenDates(select.date,this.searchNextTime(select.date,this.state.events))
          const dif2 = timeBetweenDates(select.date,this.searchNextTime(select.date,this.rellenaHorario(this.props.config)))
          if(dif1>=this.state.time && dif2>=this.state.time){
            this.seleccionaFecha(select.date)
          }else if(dif1<this.state.time){
            Swal.fire({
               title: 'Tienes que seleccionar una hora que tenga disponible '+getTimeFromSeconds(this.state.time*60)+' antes de la proxima reserva',
               icon: 'error',
               confirmButtonText: 'Vale'
            });
          }else if(dif2<this.state.time){
            Swal.fire({
               title: 'Tienes que seleccionar una hora que tenga disponible '+getTimeFromSeconds(this.state.time*60)+' antes del cierre',
               icon: 'error',
               confirmButtonText: 'Vale'
            });
          }
        }
      }
    }
  }

  searchNextTime = (date, array) =>{
    var dateRet = new Date(date.getTime() + this.state.time*60000)
    for(var i=0;i<array.length;i++){
        if(array[i].startTime){
          if(date.getDay() === array[i].daysOfWeek[0]){
            if(isBetweenDates(date,new Date(dateToStringDate(date)+' '+array[i].startTime),new Date(dateToStringDate(date)+' '+array[i].endTime))){
              dateRet = dateToStringDate(date)+' '+array[i].endTime
              break;
            }
          }
        }else{
          if(compareDates(date,new Date(array[i].start)) === -1){
            dateRet = array[i].start
            break;
          }
        }
    }
    return new Date(dateRet)
  }

  handleChangeView = (event) =>{
    this.setState({events:[this.state.selectEvent]})
    if(event.view.type === 'timeGridDay'){
      //if(event.start.getTime()>=new Date().getTime()){
        this.props.setLoading(true);
        const qr = window.location.pathname.split('/')[2]
        const fecha = event.startStr.substring(0,12)
        fetch(CONFIG.api + "Reservas?Action=getEventosPorServicio&id=" + this.state.servicio+"&fecha="+fecha+"&qr="+qr)
          .then(res => res.json())
          .then((result) => {
            if (!isEmpty(result)) {
                for(var i=0;i<result.length;i++){
                  result[i].allDay = result[i].allDay === 'false' ? false : true
                }
                result.push(this.state.selectEvent)
                this.setState({events:result})
            }else{
              this.setState({events:[this.state.selectEvent]})
            }
            this.props.setLoading(false)
        })
      //}
    }
  }

  rellenaHorario = config => {
      let businessHours = [];
      if(config.lunesd !== '' || config.lunesd2 !== ''){
        config.lunesd !== '' && businessHours.push({daysOfWeek: [1], startTime: config.lunesd, endTime: config.lunesh})
        config.lunesd2 !== '' && businessHours.push({daysOfWeek: [1], startTime: config.lunesd2, endTime: config.lunesh2})
      }
      if(config.martesd !== '' || config.martesd2 !== ''){
        config.martesd !== '' && businessHours.push({daysOfWeek: [2], startTime: config.martesd, endTime: config.martesh})
        config.martesd2 !== '' && businessHours.push({daysOfWeek: [2], startTime: config.martesd2, endTime: config.martesh2})
      }
      if(config.miercolesd !== '' || config.miercolesd2 !== ''){
        config.miercolesd !== '' && businessHours.push({daysOfWeek: [3], startTime: config.miercolesd, endTime: config.miercolesh})
        config.miercolesd2 !== '' && businessHours.push({daysOfWeek: [3], startTime: config.miercolesd2, endTime: config.miercolesh2})
      }
      if(config.juevesd !== '' || config.juevesd2 !== ''){
        config.juevesd !== '' && businessHours.push({daysOfWeek: [4], startTime: config.juevesd, endTime: config.juevesh})
        config.juevesd2 !== '' && businessHours.push({daysOfWeek: [4], startTime: config.juevesd2, endTime: config.juevesh2})
      }
      if(config.viernesd !== '' || config.viernesd2 !== ''){
        config.viernesd !== '' && businessHours.push({daysOfWeek: [5], startTime: config.viernesd, endTime: config.viernesh})
        config.viernesd2 !== '' && businessHours.push({daysOfWeek: [5], startTime: config.viernesd2, endTime: config.viernesh2})
      }
      if(config.sabadod !== '' || config.sabadod2 !== ''){
        config.sabadod !== '' && businessHours.push({daysOfWeek: [6], startTime: config.sabadod, endTime: config.sabadoh})
        config.sabadod2 !== '' && businessHours.push({daysOfWeek: [6], startTime: config.sabadod2, endTime: config.sabadoh2})
      }
      if(config.domingod !== '' || config.domingod2 !== ''){
        config.domingod !== '' && businessHours.push({daysOfWeek: [0], startTime: config.domingod, endTime: config.domingoh})
        config.domingod2 !== '' && businessHours.push({daysOfWeek: [0], startTime: config.domingod2, endTime: config.domingoh2})
      }
      return businessHours;
    }

  render() {
    const classes = {
      /*float: 'left',
      maxWidth: '400px',
      width: '80%',*/
      boxShadow: 24,
      p: 4,
    };
    const config = this.props.config
    const businessHours= this.rellenaHorario(config)

    return (
      <Box style={classes}>
          <FullCalendar
            headerToolbar={{ left: 'dayGridMonth', center: 'title', right: 'today'}}
            titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
            locale={esLocale} plugins={[ dayGridPlugin, timeGridPlugin , interactionPlugin  ]}
            initialView="dayGridMonth" dateClick={this.handleDateClick}
            datesSet={this.handleChangeView}
            ref={this.calendarRef} allDaySlot={false}
            businessHours={businessHours} events={this.state.events}
          />
      </Box>

    )
  }
}
